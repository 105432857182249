.timer {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .success-img {
    width: 120px;
  }
  .admin-goalAmount {
    color: #ddd;
    font-size: 16px;
    text-align: center;
  }
  section {
    &:nth-child(3) {
      width: 175px;
    }
  }
  .team-goals {
    .time-remaining {
      img {
        width: 120px;
      }
      .CircularProgressbar {
        height: 100px;
        top: 35px;
      }
      .t-timer {
        line-height: 20px;
        left: 42%;
        .hr {
          font-size: 18px
        }
        .min {
          font-size: 15px
        }
        .sec {
          font-size: 13px;
        }
      }
      .t-labels {
        line-height: 20px;
        top: 60%;
        right: 15%;
        .hr {
          font-size: 15px;
        }
        .min {
          font-size: 12px;
        }
        .sec {
          font-size: 6px;
        }
      }
    }
  }
  .product_pledged {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .RSPBprogressBar {
    height: 10px !important;
  }
  .percent_br {
    margin-bottom: 0;
    color: #ddd;
  }
}