.team-management-form {
  .page-name {
    position: relative;
    .base-url {
      position: absolute;
      z-index: 999;
      top: 7px;
      left: 10px;
      font-weight: 500;
    }
    .checkout_input {
      input {
        padding-left: 130px;
        font-size: 14px;
      }
    }
  }
}