.select-option {
  select {
    color: #888;
  }
  option:not(:checked) {
    color: #333;
  }
  // select{
  //     &:focus {
  //         font-size: 14px;
  //         color: #5b5b5b;
  //     }
  // }
}

.form-name-mobile {
  display: none;
}

@media (max-width: 436px) {
  .form-name-web {
    display: none;
  }
  .form-name-mobile {
    display: block;
  }
}
