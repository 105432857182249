@import '../../../index.scss';

.mp-content-area {
  .sticky {
    position: fixed;
    width: 100%;
    z-index: 100;
  }
  .assignedProspects {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #008eb5;
    color: #fff;
    font-family: $proxima-nova;
    i {
      margin-right: 10px;
    }
  }
}