.outer-box {
    display: flex;
    flex-wrap: wrap;
    height: 390px;
    overflow-x: hidden;
    overflow-y: auto;
}
.outer-box .messg-box {
    align-items: center;
    background-color: #fff;
    border: 2px solid #f8f8f8;
    box-shadow: 5.945px 5.353px 5px rgba(0, 0, 0, 0.08);
    display: flex;
    height: 160px;
    margin: 0px 15px 20px 35px;
    padding: 10px 15px 10px 0;
    position: relative;
    width: 28%;
}
.outer-box .messg-box span.img-span {
    align-items: center;
    background-color: #f4f4f4;
    border: 1px solid rgba(118, 118, 118, 0.21);
    border-radius: 50%;
    display: flex;
    height: 70px;
    justify-content: center;
    left: -35px;
    position: absolute;
    width: 70px;
}
.outer-box .messg-box .messg-box-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    margin-left: 50px;
    width: 83%;
}
.outer-box .messg-box .messg-box-content h5 {
    color: #4c4b4b;
    font-size: 18px;
    font-weight: 600;
}
.outer-box .messg-box .messg-box-content p.text {
    color: #4c4b4b;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 0;
    max-width: 180px;
}
.outer-box .messg-box .messg-box-content p.date {
    align-items: center;
    color: #b3b3b3;
    display: flex;
    font-size: 12px;
    justify-content: space-between;
    margin: -2px 0 0;
}
.outer-box .messg-box .messg-box-content p.date span {
    color: #4c4b4b;
    font-size: 25px;
    font-weight: 600;
}
.outer-box .messg-box .messg-box-content p.team {
    color: #4c4b4b;
    font-size: 12px;
    margin: -5px 0 0;
}
.outer-box .messg-box .messg-box-content p.team span {
    color: #4199b5;
}
.truncate-overflow {
    --max-lines: 2;
    position: relative;
    max-height: calc(28px * var(--max-lines));
    overflow: hidden;
    padding-right: 1rem;
}
.truncate-overflow::before {
    position: absolute;
    content: "...";
    bottom: -5px;
    right: 0;
    font-size: 30px;
}
.truncate-overflow::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
}

.tabs-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 auto 30px;
    width: 85%;
    h1 {
        color: #4199b5;
        font-size: 40px;
        letter-spacing: 2px;
        text-align: center;
        span {
            display: block;
            font-size: 20px;
            letter-spacing: 1px;
        }
    }
    .donorList {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            cursor: pointer;
            display: inline;
            font-size: 16px;
            font-weight: 500;
            padding: 0 37px 10px;
            position: relative;
            transition: all 0.3s ease-in-out;
            &.active {
                border-bottom: 3px solid #4199b5;
                color: #4199b5;
                font-size: 18px;
                position: relative;
                &:hover {
                    font-size: 18px;
                }
                // &::after {
                //     border-bottom: 7px solid transparent;
                //     border-left: 7px solid transparent;
                //     border-right: 7px solid transparent;
                //     border-top: 7px solid #4199b5;
                //     bottom: -17px;
                //     content: "";
                //     display: block;
                //     height: 5px;
                //     position: absolute;
                //     right: 0;
                //     width: 5px;
                // }
            }
            &:hover {
                font-size: 17px;
            }
            &.donor {
                text-transform: uppercase;
            }
            &.teams {
                text-transform: uppercase;
            }
            &.about {
                padding-right: 0;
            }
            span {
                font-size: 17px;
                font-weight: 600;
                margin-right: 5px;
            }

            .steps {
                position: absolute;
                right: 2px;
                top: 42px;
            }
            .triangle-demo {
                background: tan;
                border-top: 0 solid #4199b5;
                border-left: 0 solid hsl(46, 86%, 72%);
                border-bottom: 0 solid #d94948;
                border-right: 0 solid #8db434;
                left: -7px;
                position: absolute;
                top: -7px;
            }
            .step-1 .triangle-demo {
                border-top-width: 7px;
            }
            .step-2 .triangle-demo {
                border-left-width: 7px;
            }
            .step-3 .triangle-demo {
                border-right-width: 7px;
            }
            .step-4 .triangle-demo {
                border-bottom-width: 0;
            }
            .step-6 .triangle-demo {
                background: transparent;
            }
            .step-7 .triangle-demo {
                width: 0;
                height: 0;
            }
            .step-8 .triangle-demo {
                border-left-color: transparent;
            }
            .step-9 .triangle-demo {
                border-right-color: transparent;
            }
            .step-10 .triangle-demo {
                border-bottom-color: transparent;
            }
        }
    }

    // .donor-tabs {
    //     align-items: center;
    //     display: flex;
    //     margin-left: 30px;
    //     .teams-div {
    //         font-weight: 600;
    //         margin-top: 14px;
    //         padding-left: 15px;
    //     }
    //     .donor-tab {
    //         font-size: 14px;
    //         min-width: 130px;
    //         &.teams {
    //             min-width: 95px;
    //             margin-left: -16px;
    //         }
    //         &.power {
    //             text-transform: capitalize;
    //         }
    //         &.about {
    //             min-width: 86px;
    //             text-transform: capitalize;
    //         }
    //         .MuiTab-textColorPrimary-13.MuiTab-selected-15 {
    //             color: #4199b5 !important;
    //         }
    //     }
    // }
}

.btn-create-team {
    background-color: #b8b8b8;
    border: 2px solid #e5e5e5;
    border-radius: 0 0 30px 0;
    font-size: 17px;
    color: #2d2d2d;
    font-weight: 400;
    height: 54px;
    margin: 0 0 15px 25px;
    padding: 0;
    position: relative;
    text-align: center;
    width: 92%;
    img {
        left: -26px;
        position: absolute;
        top: 0;
    }
}

.team-box-outer {
    background-color: #fff;
    border: 2px solid #f8f8f8;
    box-shadow: 5.945px 5.353px 5px rgba(0, 0, 0, 0.08);
    height: 275px;
    margin: 0 20px 20px 0;
    width: 47%;
    .team-box-latest {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
        padding: 20px;
        position: relative;
        width: 100%;
        .username {
            align-items: center;
            display: flex;
            span.img-span {
                align-items: center;
                background-color: #f4f4f4;
                border: 1px solid rgba(118, 118, 118, 0.21);
                border-radius: 50%;
                display: flex;
                height: 70px;
                justify-content: center;
                margin-right: 15px;
                width: 70px;
            }
            h4 {
                color: #4c4b4b;
                font-size: 26px;
                font-weight: 400;
                margin: 0;
            }
        }
        .content {
            margin-left: 25%;
            padding: 0;
            width: 69%;
            .text {
                color: #4c4b4b;
                font-size: 13px;
                font-weight: 300;
                margin-bottom: 0;
                width: 240px;
            }
        }
    }

    .price-box {
        align-items: stretch;
        display: flex;
        justify-content: space-between;
        padding: 10px 20px 20px;
        p {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .price {
                display: inline-block;
                font-size: 36px;
                line-height: 48px;
                margin-bottom: 0;
            }
            .price-text {
                color: #707070;
                font-size: 13px;
                font-weight: 500;
                text-transform: uppercase;
            }
        }
        .btn-view {
            background: transparent;
            border: 1px solid #333;
            border-radius: 0;
            font-size: 18px;
            text-transform: uppercase;
            transition: all 0.3s ease-in-out;
            &:hover {
                transform: scale(1.05);
            }
            &:focus {
                background-color: #e0e0e0;
                outline: none;
            }
        }
    }

    .progress-bar-latest {
        margin: 0 20px;
        position: relative;
        .RSPBprogressBar {
            height: 10px;
            line-height: 1;
            border-radius: 0;
            position: relative;
            background-color: rgba(211, 211, 211, 0.6);
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 0;
            .RSPBprogression {
                border-radius: 0;
            }
        }

        .steps {
            left: 0;
            position: absolute;
            .percent {
                font-size: 18px;
                font-weight: 300;
                left: -12px;
                position: absolute;
                top: -30px;
            }
        }
        .triangle-demo {
            background: tan;
            border-top: 0 solid #4199b5;
            border-left: 0 solid hsl(46, 86%, 72%);
            border-bottom: 0 solid #d94948;
            border-right: 0 solid #8db434;
            left: -7px;
            position: absolute;
            top: -7px;
        }
        .step-1 .triangle-demo {
            border-top-width: 7px;
        }
        .step-2 .triangle-demo {
            border-left-width: 7px;
        }
        .step-3 .triangle-demo {
            border-right-width: 7px;
        }
        .step-4 .triangle-demo {
            border-bottom-width: 0;
        }
        .step-6 .triangle-demo {
            background: transparent;
        }
        .step-7 .triangle-demo {
            width: 0;
            height: 0;
        }
        .step-8 .triangle-demo {
            border-left-color: transparent;
        }
        .step-9 .triangle-demo {
            border-right-color: transparent;
        }
        .step-10 .triangle-demo {
            border-bottom-color: transparent;
        }
    }
}
