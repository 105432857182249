@import '../../index.scss';

.case_study {
  position: relative;
  padding: 40px 0px 60px;
  .head_section .heading_tag {
    padding-left: 80px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    left: 0;
    top: 0px;
    color: $white;
    font-family: $Roboto;
    font-weight: 900;
    font-size: 24px;
    height: 55px;
    line-height: 50px;
  }
  .sliderCaseStudy {
    .slick-prev:before {
      color: gray;
    }
    .slick-next:before {
      color: gray;
    }
    .slick-track {
      display: flex;
      .slick-slide {
        height: inherit;
        display: flex;
        div:first-child {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  .featured_grid {
    position: relative;
    margin-top: 60px;
    padding: 0 10px;
    height: 100%;
    .featured_img {
      position: relative;
      img{
        width: 100%;
      }
    }
    .featured_desc {
      background: #f3f3f3;
      padding: 20px;
      height: 100%;
      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          color: $dark_grey;
          font-size: 15px;
          position: relative;
          padding-left: 25px;
          margin-bottom: 10px;
          img {
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .featured_item ul.f_item_uline li {
      height: 50px;
      color: $white;
      text-align: center;
      font-family: $Roboto-slab;
      font-weight: 700;
      font-size: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:nth-child(odd) {
        background: $light_green;
      }
      &:nth-child(even) {
        background: #6baa37;
      }
      img {
        max-width: 18px;
        margin-right: 7px;
      }
    }
    .featured_img .case-study-badge {
      position: absolute;
      bottom: -30px;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      .left, .right {
        font-family: $Roboto-slab;
        font-size: 16px;
        color: $white;
        width: 100px;
        height: 60px;
        display: flex;
        padding: 15px 0;
        justify-content: center;
        font-weight: 700;
      }
      .left {
        margin-right: -7px;
      }
      .right {
        margin-left: -7px;
      }
      .center {
        border: 5px solid $light_green;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        img {
          height: 35px;
          width: 35px;
        }
      }
    }
  }
}