@import "../../index.scss";

.checkout_component_wrapper {
	background-image: linear-gradient(rgb(206,254,218), rgb(239, 240, 243));
  background-repeat: no-repeat;
  margin: auto;
}
.wrapper {
  font-family: 'roboto';
  width: 1140px;
  margin: auto;
  position: relative;
  padding-top: 40px;
  display: block;
  @media (max-width: 1199px) {
      width: 940px;
  }
  @media (max-width: 995px) {
      width: 100%;
  }
  @media (max-width: 320px) {
      width: 300px;
  }
}
.content {
  display: inline-block;
  margin: 0;
  padding: 0 0 40px 0;
  width: 65%;
  background: transparent;
  min-height: auto;
  @media (max-width: 1199px) {
    width: 63%;
  }
  @media (max-width: 995px) {
    width: 100%;
  }
  .mt40 {
    margin-top: 40px;
  }
  .donate-btn {
    background: #0085ad;
    display: flex;
    width: 50%;
    font-size: 26px;
    color: #fff;
    margin: 40px auto 0;
  }
  .back-btn {
    color: #fff;
    background: #91a607;
    margin-bottom: 15px;
    width: 200px;
    font-size: 18px;
  }
  .donate-btn:hover,.back-btn:hover {
    background: #0a7191;
  }
  .submit-donation {
    position: relative;
    .circular-loader {
      position: absolute;
      top: 10%;
      left: 35%;
      color: #fff;
    }
  }
}

.user-info {
  padding-bottom: 0 !important;
}

.checkout {
  background-color: #e7eff2 !important;
  padding: 30px 20px;
  box-shadow: none !important;
  position: relative;
  .number {
    position: absolute;
    width: 50px;
    height: 50px;
    top: -25px;
    left: calc(50% - 28px);
    background: #058cb4;
    border-radius: 50%;
    span {
      position: absolute;
      border: 1px solid #fff;
      border-radius: 50%;
      font-size: 28px;
      font-weight: 500;
      color: #fff;
      width: 45px;
      height: 45px;
      text-align: center;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .form-name {
    margin-bottom: 5px;
    font-size: 14px;
    color: #5b5b5b;
    font-weight: bolder;
    span {
      color: #ed5565;
    }
  }
  .input-base {
    border-radius: 4px;
    width: 100%;
    padding-left: 10px;
    background: #fff;
    font-size: 16px;
  }
  .raffle-check {
    label {
      span {
        font-size: 14px;
      }
    }
  }
  .select {
    width: 100%;
    background: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    select {
      padding-left: 10px;
      padding-right: 22px;
      &:focus {
        background: transparent;
      }
    }
  }
  .checkout_input {
    border-radius: 4px;
    border: none;
    width: 100%;
    p {
      font-size: 13px;
    }
    .MuiInput-underline-179.MuiInput-error-180, .MuiInput-underline-185.MuiInput-error-186 {
      border: 1px solid #f44336;
      border-radius: 4px;
    }
    input {
      padding-left: 10px;
      background: #fff;
      font-size: 16px;
      border-radius: 4px;
    }
    :after {
      border: none;
    }
    :before {
      border: none;
    }
  }
  .checkout_input:hover {
    ::before {
      border: none !important;
    }
  }
  .method{
    width: 100%;
    .payment-method {
      span {
        font-size: 14px;
      }
    }
  }
  .MuiFormControlLabel-label-207 {
    font-size: 16px;
    color: #5b5b5b;
  }
}

.total_donation {
  background: #ea8502;
  padding: 10px;
  font-weight: 500;
  margin-bottom: 60px;
  .my_donation {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
        color: #ea8502;
        font-size: 36px;
        text-align: center;
        border-bottom: 1px solid #eee;
        line-height: 40px;
        margin: 0 0 10px;
    }
    span {
      line-height: 85px;
      color: #d07600;
      text-align: center;
      font-size: 36px;
      font-weight: 700;
      text-transform: uppercase;
      label {
        color: #47494a;
        font-size: 80px;
      }
    }
  }
    .total_fund {
        color: white;
        p {
            display: block;
            font-size: 21px;
            font-weight: normal;
            text-align: center;
            margin-top: 4px;
            margin-bottom: 3px;
            @media (max-width: 995px) {
                display: none;
            }
        }
        h5 {
            display: inline-block;
            width: 50%;
            font-size: 22px;
            font-weight: 700;
            line-height: 24px;
            padding-top: 10px;
            margin: 0;
            vertical-align: top;
            @media (max-width: 995px) {
                width: 100%;
                text-align: center;
            }
        }
        div {
            display: inline;
            margin-left: 30px;
            @media (max-width: 995px) {
                display: block;
                width: 100%;
                margin: 0;
                text-align: center;
            }
            strong {
                font-weight: 100;
                font-size: 50px;
                margin: 0;
                padding: 0;
                .multiple {
                    font-weight: 700;
                }
                .currency {
                    font-weight: 700;
                    font-size: 52px;
                }
                .fund_amount {
                    font-weight: 700;
                    font-size: 50px;
                }
            }
        }
    }
}

.process_fee{
  background: #CCEFF2;
  display: block;
  padding: 15px;
    label {
      color: #5b5b5b;
      font-size: 20px;
      font-weight: 700;
      .yes {
        margin-left: 15px;
        color: #0085ad;
        font-size: 25px;
      }
    }
}
