.thankyou-wrapper-newUI {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: auto;
  }
  .header {
    margin-top: 20px;
    width: 100%;
    background: #7ebcc8;
    background-image: linear-gradient(left, #62a3b2, #81bfcc, #62a3b2);
    // padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1 {
      color: #fff;
      font-weight: 1000;
    }
    h6 {
      color: #fff;
      font-weight: 700;
    }
    img {
      // margin-top: 5px;
    }
  }
  .background {
    width: 100%;
    background: #7ebcc8;
    background-image: linear-gradient(left, #62a3b2, #81bfcc, #62a3b2);

    .message {
      background: white;
      width: 100%;
      margin: 20px 0 5px 0;
      padding: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .step {
        margin-left: 140px;
        background: white;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        border: solid #41b54a 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        .innerCircle {
          background-color: #41b54a;
          color: white;
          width: 110px;
          height: 110px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-shadow: 0px 1px, 1px 0px, 1px 1px;
          letter-spacing: 1px;
          font-weight: 900px;
        }
      }
      .right {
        text-align: left;
        margin-left: 40px;
        p {
          font-size: 16px;
        }
        div {
          color: black;
          font-size: 22px;
          font-weight: 700px;
        }
        button {
          margin-top: 15px;
          background-color: #41b54a;
          border-radius: 7px;
          width: 150px;
          padding-top: 10px;
          padding-bottom: 10px;
          border: none;
          text-shadow: 0px 1px, 1px 0px, 1px 1px;
          letter-spacing: 2px;
          font-weight: 900px;
          color: white;
        }
      }
    }

    .center {
      text-align: center;
    }
  }
  .footer {
    margin-top: 20px;
    width: 100%;
    background: #68bd45;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 20px;
      background: #3684a9;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      color: #68bd45;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px;
    }
  }
}
.thankyou-wrapper {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: auto;
  }
  .header {
    margin-top: 20px;
    width: 100%;
    background: #68bd45;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h3 {
      color: #fff;
      font-weight: 600;
    }
    h6 {
      color: #fff;
      font-weight: 600;
    }
    img {
      margin-top: 10px;
    }
  }

  .message {
    width: 50%;
    margin: 20px 0;
    padding: 0;
    p {
      font-size: 20px;
      span {
        color: #68bd45;
      }
      .link {
        color: #68bd45;
        text-decoration: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .center {
    text-align: center;
  }

  .footer {
    margin-top: 20px;
    width: 100%;
    background: #68bd45;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 20px;
      background: #3684a9;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      color: #68bd45;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2px;
    }
  }
}

@media (max-width: 1280px) {
  .thankyou-wrapper-newUI {
    .message {
      width: 40%;
    }
  }
  .thankyou-wrapper {
    .message {
      width: 50%;
    }
  }
}

@media (max-width: 991px) {
  .thankyou-wrapper {
    .message {
      width: 50%;
    }
  }
}

@media (max-width: 767px) {
  .thankyou-wrapper-newUI {
    .background {
      .message {
        padding: 25px 0;
        flex-direction: column;
        align-items: center;
        .step {
          margin-left: 0px;
          background: white;
          width: 120px;
          height: 120px;
          border-radius: 50%;
          border: solid #41b54a 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          .innerCircle {
            background-color: #41b54a;
            color: white;
            width: 110px;
            height: 110px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-shadow: 0px 1px, 1px 0px, 1px 1px;
            letter-spacing: 1px;
            font-weight: 900px;
          }
        }
        .right {
          padding-top: 20px;
          text-align: center;
          margin-left: 0px;
          p {
            font-size: 14px;
          }
          div {
            color: black;
            font-size: 18px;
            font-weight: 700px;
          }
          button {
            margin-top: 15px;
            background-color: #41b54a;
            border-radius: 7px;
            width: 150px;
            padding-top: 10px;
            padding-bottom: 10px;
            border: none;
            text-shadow: 0px 1px, 1px 0px, 1px 1px;
            letter-spacing: 2px;
            font-weight: 900px;
            color: white;
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .thankyou-wrapper {
    .message {
      width: 65%;
    }
  }
}

@media (max-width: 550px) {
  .thankyou-wrapper {
    .message {
      width: 80%;
    }
  }
}

@media (max-width: 480px) {
  .thankyou-wrapper-newUI {
    .background {
      .message {
        padding: 25px 0;
        flex-direction: column;
        align-items: center;
        .step {
          margin-left: 0px;
          background: white;
          width: 120px;
          height: 120px;
          border-radius: 50%;
          border: solid #41b54a 2px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          .innerCircle {
            background-color: #41b54a;
            color: white;
            width: 110px;
            height: 110px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-shadow: 0px 1px, 1px 0px, 1px 1px;
            letter-spacing: 1px;
            font-weight: 900px;
          }
        }
        .right {
          padding-top: 20px;
          text-align: center;
          margin-left: 0px;
          p {
            font-size: 14px;
          }
          div {
            color: black;
            font-size: 18px;
            font-weight: 700px;
          }
          button {
            margin-top: 15px;
            background-color: #41b54a;
            border-radius: 7px;
            width: 150px;
            padding-top: 10px;
            padding-bottom: 10px;
            border: none;
            text-shadow: 0px 1px, 1px 0px, 1px 1px;
            letter-spacing: 2px;
            font-weight: 900px;
            color: white;
          }
        }
      }
    }
  }
}
