@import "../../index.scss";

.middle-section {
  width: 100%;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  font-family: $proxima-nova;
  .campaign-checkbox {
    width: 40%;
    h3 {
      margin-bottom: 0;
    }
    .columns {
      .label {
        width: 50%;
        margin: 0;
        padding: 0;
        span {
          font-size: 14px;
          font-family: $proxima-nova;
          &:first-child {
            padding: 2px;
          }
        }
      }
      .checkbox {
        color: #7c6a9c;
      }
    }
  }

  .filters {
    font-family: $proxima-nova;
    width: 23%;
    .export-actions {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 20px;
      button {
        background: #333;
        color: white;
        padding: 10px;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 14px;
        border-radius: 5px;
        width: 130px;
        a {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
        a:hover {
          text-decoration: none;
          color: #e7e7e7;
        }
        &:last-child {
          flex-grow: 1;
        }
      }
    }
    .report-filters {
      width: 100%;
      .title {
        background: #7c6a9c;
        border-radius: 8px 8px 2px 0px;
        display: flex;
        align-items: center;
        label {
          width: 50%;
          color: #fff;
          text-align: center;
          margin: 5px 0;
        }
        .left {
          border-right: 1px solid #fff;
        }
      }
      .filter-by {
        padding: 5px 0;
        background: #f3f4f4;
        width: 100%;
        display: flex;
        flex-direction: row;
        .left {
          width: 50%;
          border-right: 1px solid #7c6a9c;
          padding-left: 5px;
        }
        .right {
          width: 50%;
          padding-left: 10px;
        }
        .radio-item {
          margin: 0;
          .radio {
            color: #7c6a9c;
            padding: 3px;
            svg {
              height: 20px;
              width: 20px;
            }
          }
          .campaign-radio-label {
            display: flex;
            align-items: center;
            color: #7e7e7e;
            font-size: 14px;
            font-family: $proxima-nova;
          }
        }
      }
      .donor-types {
        margin-top: 20px;
        .sort-by-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: #8d8988;
          font-size: 14px;
          font-weight: 700;
          padding-bottom: 5px;
          span {
            color: #7c6a9c;
          }
        }
      }
    }
  }
}
