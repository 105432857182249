.team-management-container {
  .team-main-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
    padding: 0 60px;
    .add-team-btn {
      height: 40px;
      background: #aae7f8;
      color: #008eb5;
      border-radius: 4px;
      box-shadow: none;
      font-size: 18px;
      line-height: normal;
      i {
        padding-right: 5px;
      }
    }
  }
}


.cta-downlaod-btn{
  height: 40px;
      background: #aae7f8;
      color: #008eb5;
      border-radius: 4px;
      box-shadow: none;
      font-size: 16px;
      line-height: normal;
      position: absolute;
      top:60px;
      left:20px;
      border:0;
      padding: 0 20px;
      text-transform: uppercase;
      font-weight: 550;
}