@import "../../index.scss";

#header {
  border-top: 3px solid $dark_green;
  z-index: 10;
  position: relative;

  nav.navbar.navbar-inverse {
    margin-bottom: 0px;
    padding: 0;
    background: $white;
    border: none;
    transition: all 0.3s;
    -webkit-transition: all 0.3s;

    .navbar-header {
      .menu-opener {
        display: none;
      }

      .menu-opener {
        -webkit-transition: 250ms all;
        transition: 250ms all;

        &:hover,
        &.open_menu {
          -webkit-transition: 250ms all;
          transition: 250ms all;
        }
      }

      .menu-opener-inner {
        -webkit-transition: 250ms all;
        transition: 250ms all;

        &::before,
        &::after {
          -webkit-transition: 250ms all;
          transition: 250ms all;
        }
      }

      .menu-opener {
        cursor: pointer;
        height: 64px;
        position: absolute;
        top: 2%;
        right: 1%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 64px;
      }

      .menu-opener-inner {
        background: #45484a;
        height: 2px;
        margin-left: 1rem;
        margin-top: 40px;
        width: 40px;

        &::before,
        &::after {
          background: #45484a;
          content: "";
          display: block;
          height: 2px;
          width: 40px;
        }

        &::before {
          -webkit-transform: translateY(-1rem);
          -ms-transform: translateY(-1rem);
          transform: translateY(-1rem);
        }

        &::after {
          -webkit-transform: translateY(1rem);
          -ms-transform: translateY(1rem);
          transform: translateY(1rem);
        }

        &.open_menu {
          background: transparent;
          margin-top: 35px;

          &::before {
            -webkit-transform: translateY(0rem) rotate(-45deg);
            -ms-transform: translateY(0rem) rotate(-45deg);
            transform: translateY(0rem) rotate(-45deg);
          }

          &::after {
            -webkit-transform: translateY(-0.2rem) translateX(0rem) rotate(45deg);
            -ms-transform: translateY(-0.2rem) translateX(0rem) rotate(45deg);
            transform: translateY(-0.2rem) translateX(0rem) rotate(45deg);
          }
        }
      }

      .navbar-brand {
        text-shadow: none;
        color: $light_grey;
        font-size: 16px;
        font-family: $Nunito;
        font-weight: 600;
        border-bottom: 2px solid transparent;
      }
    }

    #myNavbar {
      -webkit-transition: 250ms all;
      transition: 250ms all;

      &.open_menu {
        -webkit-transition: 250ms all;
        transition: 250ms all;
      }

      .btn.btn-round {
        margin-top: 25px;
        border-radius: 20px;
        background: $light_green;
        border: none;
        padding: 10px 20px;
        font-weight: 900;
        font-size: 13px;
        text-transform: uppercase;

        img {
          max-width: 20px;
          margin-top: -5px;
        }
      }

      ul.nav.navbar-nav.navbar-right {
        margin-right: 15px;

        li {
          position: relative;
          display: block;
          padding-left: 10px;
          padding-right: 10px;

          a {
            text-shadow: none;
            color: $light_grey;
            font-size: 16px;
            font-family: $Nunito;
            font-weight: 600;
            border-bottom: 2px solid transparent;

            &.active {
              color: $dark_green;
              border-bottom: 2px solid $dark_green;
            }

            img {
              max-width: none;
              display: block;
              text-align: center;
              margin: 0 auto;
              padding-bottom: 15px;
              max-height: 38px;
            }

            &:focus,
            &:active,
            &:hover {
              color: $dark_green;
              background-color: transparent;
              text-shadow: none;
              font-size: 16px;
              font-weight: 600;
              border-bottom: 2px solid $dark_green;
            }
          }
        }
      }
    }

    &.latest-navbar {
      padding: 5px 0;
      .navbar-brand {
        padding: 0 15px;
      }
      #myNavbar {
        align-items: center;
        display: flex !important;
        justify-content: space-between;
        padding: 7px 0 0;
        .nav {
          margin-left: auto;
          li {
            a {
              padding: 10px 15px;
              transition: all 0.3s ease-in-out;
              &:hover {
                border-bottom: 2px solid #4bbdd9 !important;
                color: #4bbdd9 !important;
              }
              &.dashboard-latest {
                background-color: #cecece;
                border: none !important;
                color: #fff !important;
                font-size: 14px !important;
                font-weight: 600;
                margin: 0;
                padding: 8px;
                text-align: center;
                text-transform: uppercase;
                width: 130px;
                &:hover {
                  background-color: #4bbdd9 !important;
                  border: none !important;
                  color: #fff !important;
                }
              }
              &.login-latest {
                background-color: #898989;
                border: none !important;
                color: #fff !important;
                font-size: 14px !important;
                font-weight: 600;
                padding: 8px 0;
                text-align: center;
                text-transform: uppercase;
                width: 130px;
                &:hover {
                  background-color: #4bbdd9 !important;
                  border: none !important;
                  color: #fff !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #header {
    padding: 10px;
    .navbar.navbar-inverse {
      height: 70px;
    }
  }
}
