
@import '../../index.scss';

.why_pledje {
    background: #effee3;
    position: relative;
    padding: 60px 0px 40px;
    border-top: 3px solid $white;
    .head_section .heading_tag {
      padding-right: 80px;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      left: 0;
      top: 0px;
      color: $white;
      font-family: $Roboto;
      font-weight: 900;
      font-size: 24px;
      height: 55px;
      line-height: 50px;
    }
    .why_grid_main {
      margin-top: 50px;
      .w_grid {
        img {
          width: 18%;
          float: left;
          margin-right: 5%;
        }
        min-height: 100px;
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
        .why_txt {
          float: left;
          width: 76%;
          h3 {
            color: $light_green;
            font-family: $Roboto-slab;
            font-weight: 700;
            margin-top: 0;
          }
          p {
            color: #767676;
            font-family: $Roboto;
            margin-bottom: 0;
            font-size: 19px;
          }
          .tools-succeed-text{
            color: #767676;
            font-family: "Roboto", sans-serif;
            margin-bottom: 0;
            font-size: 19px;
            list-style-type: disc;
            padding-left: 25px;
          }
        }
      }
    }
  }