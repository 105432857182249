.cart-checkout-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background: #5485b4;
  width: 100%;
  margin-bottom: 40px;
  .items {
    width: 33%;
    align-items: center;
    button {
      font-size: 16px;
      border: none;
      border-radius: 4px;
      padding: 8px;
    }
    .edit-btn {
      width: 70%;
      background: #91a607;
      color: #fff;
    }
    .actions {
      justify-content: space-between;
      width: 70%;
      color: #fff;
      .save-btn {
        background: #77AF46;
      }
      .cancel-btn {
        background: #D66858; 
      }
    }
    .all-items {
      max-height: 100px;
      overflow-y: auto;
    }
    .item {
      padding-top: 10px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      i {
        @media (max-width: 767px) {
          font-size: 18px;
        }
        font-size: 14px;
        color: #fff;
        margin-left: 5px;
        &:hover {
          cursor: pointer;
          color: #D66858;
        }
      }
      .title {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 500;
        max-width: 70%;
      }
      span {
        color: #000;
        font-size: 20px;
        input {
          width: 35px;
          border: none;
          @media (max-width: 767px) {
            margin-right: 10px;
            padding: 5px;
            width: 40px;
          }
        }
        input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
      }
      .calc {
        min-width: 45%;
        justify-content: space-between;
        align-items: center;
        label {
          font-weight: 300;
          font-size: 20px;
        }
      }
    }
  }
  .total-donation {
    width: 33%;
    background: #3977b5;
    padding: 10px 0;
    color: #fff;
    align-items: center;
    .title {
      font-size: 18px;
      font-weight: 400;
    }
    .type-group {
      width: 100%;
    }
    .type {
      align-items: center;
      margin-top: 10px;
      .divider {
        position: relative;
        width: 90%;
        margin: 15px 0;
        label {
          position: absolute;
          top: -15px;
          left: 50%;
          transform: translate(-50%, 0);
          font-size: 16px;
          background: #3977b5;
          width: 50px;
          height: 30px;
          text-align: center;
          line-height: 30px;
        }
        .hr {
          border-top: 1px solid #fff;
        }
      }
      .radio {
        margin: 0;
        .oneTime {
          color: #fff;
          font-size: 16px;
          font-weight: 300;
          padding: 0;
          span {
            font-size: 20px;
          }
        }
        .monthly {
          color: #fff;
          font-size: 16px;
          font-weight: 300;
          input {
            border: none;
            width: 30px;
            color: #000;
            margin: 0 5px;
            text-align: center;
          }
        }
      }
    }
  }
  .grad-total {
    width: 30%;
    height: 100px;
    align-items: center;
    justify-content: space-evenly;
    .matched {
      align-items: center;
      justify-content: space-between;
      color: #fff;
      height: 100px;
      label {
        font-size: 16px;
        font-weight: 300;
      }
      .factor {
        height: 50px;
        width: 50px;
        line-height: 50px;
        text-align: center;
        color: #000;
        background: #fff;
        border-radius: 50%;
        span {
          font-size: 32px;
        }
      }
    }
    .eq {
      color: #fff;
      font-size: 32px;
      font-weight: 300;
    }
    .org-rec {
      align-items: center;
      color: #fff;
      max-width: 50%;
      label {
        font-size: 14px;
        font-weight: 300;
        text-align: center;
      }
      span {
        font-size: 28px
      }
    }
  }
}

@media (max-width: 767px) {
  .cart-checkout-summary {
    flex-direction: column;
    .items {
      width: 100%;
      padding: 20px;
      button {
        width: 40%
      }
    }
    .total-donation {
      width: 100%;
    }
    .grad-total {
      width: 100%;
      height: 130px;
      .org-rec {
        height: 100px;
        justify-content: space-between;
      }
    }

  }
}