.MuiRadio-colorSecondary-233.MuiRadio-checked-230 {
    color: #3c92b5 !important;
}
.table-cell-top{
    width: 20% !important;
    max-width: 10px !important;
    min-width: 45px !important;
    padding: 3px !important;
    text-align: center;
    font-size: 10px !important;
    color:#a4a4a4!important;
    text-align: center!important;
}
.table-cell-rows{
    width: 20%  !important;
    max-width: 90px !important;
    /* min-width: 50px !important; */
    padding: 1%!important;
    font-size: 10px !important;
    color:#a4a4a4!important;
    text-align: center!important;
}
.prosp-search-mobile{
    width  : 90%;
    position: absolute;
    left: 5%;
    top : -30px;
    height : 60px;
    /* padding: 5px; */
    
}
.prosp-search-mobile .input-search{
    margin-left: 10px;
    width : calc(100% - 50px);
    margin-top : 10px;
    padding: 5px;
    font-size: 14px;
  
}
.prosp-search-mobile .search-icon{
    width: 30px;
    color: #bebebe;
    
}

.prospect-counts-mobile{
    background: #648fba!important;
    text-align: center;
    padding: 10px;
    margin-top: 10px;
    position: absolute;
    width: 94%;
}
.mobile-header{
    height: 200px !important;
    background-image: linear-gradient(to bottom , #5689b6, #a8cbda);
    position: relative;
}
.campaign-time-mobile{
    text-align: center;
    padding: 10px;
    margin-top: 60px;
    position: absolute;
    width: 94%;
}
.clear-filter{
    position: absolute!important;
    right: 0;
    width: 40px;
    z-index: 5;
    top: 5px;
    margin-right: 15px!important;
}
.filter-chips{
    display:flex;
    overflow-x: auto;
    flex-wrap: wrap;
    background-color:white!important;
    margin-bottom: 27px;
}
.filter-chip{
    border-radius: 10px!important;
    border: 1px solid!important;
    background-color:white!important;
    margin: 10px;
    color:#85aeca!important;
}
.status-container{
    margin:10px;
    margin-top: -20px;
    display : flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color:#74b3cb
}
.popover-status-container{
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #74b3cb;
}
.status-text{
    font-weight: bold;
    color: #74b3cb;
    padding-top: 8px!important;
}
.filter-text{
    color : #c3c4c3!important;
}
.status-dropdown{
    width: 240px;
    border : 2px solid!important;
    border-radius: 8px;
}
.status-dropdown-text{
    background:white;
    margin-left:10px;
    margin-right:10px;
    color:#a4a4a4!important;
}
.status-down-icon{
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    color: #74b3cb;
    width: 24px;
    height: 24px;
    margin-top: 4px;
    pointer-events: none 
}
.amount-container{
    margin:10px;
    display : flex;
    flex-direction: row;
    justify-content: space-between;
}
.amount-text{
    padding-top:5px;
    color : #c3c4c3!important;
}
.clear-apply-filter-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around
}
.filter-button{
    background: #7ab8cd!important;
    color:white!important
}
.table-container{
    margin-top: 25px;
    margin:'auto';
    // width: 300px;
    width : 100%!important;
    border: 15px solid;
    border-color: #fafafa
}
.access-prospect-btn{
    background: #7AB8CA!important;
    color: white!important;
    border-radius: 0px!important;
    white-space: nowrap;
    padding: 0px 4px 0px 4px;
    font-size: 9px;
    
}
.status-btn{
    color: white!important;
    border-radius: 0px!important;
    white-space: nowrap;
    text-align: center;
    font-size: 9px;
    font-weight: bold;
    padding: 0px 4px 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
}
.table-cell{
    color:#a4a4a4!important;
}
.web-status-cell{
    white-space :nowrap;
    color:white!important;
    font-weight:bold!important;
}
.web-table-cell{
    color:#838383!important;
    font-weight: bold!important;

}
.web-table-cell-head{
    color:#7f7f7f!important;
    font-weight: bold!important;
}
.campaign-time-mobile .time{
    color : white;
    text-align : center;
    margin : 5px;
}
.view-down-icon{
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    color: #74b3cb;
    pointer-events: none 
}
