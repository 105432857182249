.donars_section {
  padding-bottom: 0px;
  float: left;
  width: 100%;
  margin: 0;
  .donor-listing {
    max-height: 280px;
    overflow-y: auto;
  }
  .donar_header {
    position: relative;
    padding: 7px 15px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    background: #dfdfdf none repeat scroll 0 0;
    h2 {
      margin: 0;
      color: #0085ad;
      font-size: 16px;
    }
  }
  .donar_block {
    padding: 5px 10px;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.animation-donor-info {
      background-color: #f2dc91 !important;
    }
    .team_donar_img {
      border-radius: 50%;
      height: 40px;
      width: 40px;
    }
    .btn-visit {
      background-color: #0085ad;
      border-radius: 5px;
      color: #fff;
      padding: 5px 15px;
    }
    .donor_info {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      text-align: center;
      .alias {
        margin: 0;
      }
      .message {
        font-size: 10px;
        font-weight: 300;
        color: #8a8b8c;
        margin: 5px 0 0 0;
      }
    }
    .amount {
      font-size: 14px;
      color: #0085ad;
      margin: 0;
      min-width: 52px;
      text-align: center; 
    }
    img.trophy {
      float: right;
    }
  }
  .donar_block:nth-child(odd) {
    background-color: white;
  }
  .donar_block:nth-child(even) {
    background-color: #daf4fd;
  }
}

.text-highlight{
  text-align: center;
  width: 100%;
  float: left;
  font-weight: 600;
  font-size: 22px;
  color: #0085ad;
  margin-top:10px;
  margin-bottom:0px
}


.search-wrap{
  position: relative;
  width: 100%;
  .fa-search{
    position: absolute;
    left:5px;
    top:10px;
    width: 20px;height:20px;
  }
  input{
    position: relative;
    padding-left:30px;
  }
}