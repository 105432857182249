#new-camp-header {
  flex-direction: row;
  justify-content: space-between;
  background: #008eb5;
  padding: 5px 60px;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px 5px;
  }
  .title {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      margin-bottom: 10px;
    }
    .title-text {
      padding-left: 10px;
      label {
        font-size: 20px;
        font-weight: 500;
      }
    }
    button {
      background: #fff;
      padding: 8px;
    }
    svg {
      color: #008eb5;
    }
  }
  .select-box {
    display: flex;
    align-items: center;
    .select {
      width: 125px;
      margin-left: 10px;
      background: #fff;
      border-radius: 4px;
      font-size: 14px;
      color: #008eb5;
      padding-left: 4px;
      height: 30px;
      @media (max-width: 768px) {
        font-size: 12px;
        height: 40px;
      }
    }
    .new-org-btn {
      @media (max-width: 768px) {
        font-size: 12px;
        height: 40px;
      }
      margin-left: 10px;
      height: 30px;
      font-size: 14px;
      background: #fff;
      color: #008eb5;
      border-radius: 4px;
      border: none;
    }
  }
}