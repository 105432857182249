.main-prospect-area {
  display: flex;
  flex-direction: column;
  .prospect-sticky {
    position: fixed;
    top: 0;
    width: calc(100% - 270px);
    z-index: 99;
    background: #fff;
    .empty {
      width: 100%;
      overflow-x: auto;
      height: 15px;
      .scroll {
        height: 10px;
      }
    }
  }
}

@media (min-width: 991px) {
  .main-prospect-area {
    display: block;  
  }
  .mobile-prospect-area {
    display: none;
  }
}

@media (max-width: 990px) {
  .main-prospect-area {
    display: none;
  }
  .mobile-prospect-area {
    display: block;
    overflow-x: hidden;
  }
}