#new-campaign {
  .mid-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 60px;
    @media (max-width: 767px) {
      padding: 10px 0;
      flex-direction: column;
      align-items: flex-end
    }
    .edit-action {
      display: flex;
      align-items: center;
      margin: 5px;
      label {
        text-align: right;
      }
      .select {
        padding-left: 5px;
        margin-left: 5px;
        width: 200px;
        height: 40px;
        background: #d3e8e3;
        color: #3b7862;
        border-radius: 4px;
        font-size: 18px;
        @media (max-width: 891px) {
          font-size: 14px;
        }
      }
    }
    h4 {
      margin: 5px 10px;
    }
    button {
      margin: 5px;
      height: auto;
      background: #aae7f8;
      color: #008eb5;
      border-radius: 4px;
      border: none;
      font-size: 18px;
      font-weight: 500;
      @media (max-width: 891px) {
        font-size: 14px;
      }
    }
  }
}