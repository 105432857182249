@import "~react-image-gallery/styles/scss/image-gallery.scss";
.campaign_wrapper {
  background-repeat: no-repeat;
  margin: auto;
  font-family: "roboto";
  min-height: 40vh;
}

.green-bg {
  background-image: linear-gradient(rgb(166, 243, 184), rgb(216, 249, 246));
}

.blue-bg {
  background-image: linear-gradient(rgb(200, 244, 255), rgb(189, 228, 228));
}

.content {
  .camp-img {
    display: block;
    margin-top: 0px;
    width: 100%;
  }
  #camp-image {
    margin-bottom: 20px;
  }
  .instruction {
    display: flex;
    .instruction-img {
      height: 250px;
    }
    @media (max-width: 991px) {
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  .block {
    display: block;
    clear: both;
  }
  .sponsor-img {
    margin-top: 20px;
  }
  .mobile-clock {
    margin-bottom: 50px;
    display: none;
    @media (max-width: 991px) {
      display: block;
      .goals {
        .goal-point {
          text-transform: uppercase;
          color: #909090;
          font-size: 24px;
          margin-top: 15px;
          margin-bottom: 20px;
        }
      }
      .time-remaining {
        max-width: 200px;
        margin: 0 auto;
        svg {
          height: 155px;
          top: 65px;
        }
        .t-labels {
          right: 24%;
          top: 60%;
          line-height: 35px;
          .hr {
            font-size: 18px;
          }
          .min {
            font-size: 13px;
          }
          .sec {
            font-size: 8px;
          }
        }
        .t-timer {
          left: 42%;
          top: 60%;
          line-height: 35px;
          .hr {
            font-size: 28px;
          }
          .min {
            font-size: 24px;
          }
          .sec {
            font-size: 16px;
          }
        }
      }
      .bouns_goal_div {
        margin-bottom: 20px;
        text-align: center;
      }
      .bouns-goal-point {
        text-transform: uppercase;
        color: #579e19;
        font-size: 24px;
        margin-top: 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .content {
    .camp-img {
      display: none;
    }
  }
}

#description {
  .cont_mobile_version {
    display: block;
    padding: 0 15px;
    img {
      margin-bottom: 50px;
      margin-top: 30px;
      width: 100%;
      height: auto;
    }
    a {
      background: #0085ad none repeat scroll 0 0;
      border-radius: 4px;
      color: #ffffff;
      float: left;
      font-size: 14px;
      text-decoration: none;
      margin: 0;
      padding: 10px 0;
      text-align: center;
      width: 100px;
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }
}

.campaign-wrapper .project-inner-content {
  display: none;
}

.project-inner-content {
  padding: 30px 0 20px 0;
  h1 {
    color: #2995c3;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0;
    padding: 0 25px;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  h2 {
    color: #2995c3;
    text-transform: uppercase;
    text-align: left;
    padding: 0 25px;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  h3 {
    color: #2995c3;
    text-transform: uppercase;
    text-align: left;
    padding: 0 25px;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  .super_button {
    background: #1d92b6;
    border: 1px solid #1d92b6;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    padding: 8px 0;
    width: 35%;
    margin-right: 20px;
    text-align: center;
    float: right;
    display: none;
    @media (max-width: 750px) {
      display: block;
    }
    @media (max-width: 500px) {
      width: 50%;
      display: block;
      float: none;
      margin: auto;
    }
  }
  .super_pop_div {
    width: 100% !important;
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: flex-end;
    flex-flow: row wrap;
    clear: both;
    .super_donr {
      vertical-align: top !important;
      display: inline-block;
      margin: 0 10px 15px;
      position: relative;
      z-index: 1;
      text-align: center;
      width: 30%;

      img {
        max-width: 90px;
        float: left;
        width: 30%;
        vertical-align: bottom;
        height: auto;
      }
      p.donar_name {
        font-style: normal;
        font-weight: bold;
        padding-top: 8px;
        text-transform: uppercase;
        color: #686868;
      }
      p {
        display: inline-block;
        width: 60%;
        font-size: 16px;
        line-height: 16px;
        margin: 0;
        position: static;
        padding: 0 0 0 15px;
        text-align: left;
        color: #686868;
      }
    }
  }
}
.bouns_super_goal_new {
  background: rgba(0, 0, 0, 0) url(../../assets/images/bonus-round.png) no-repeat scroll 0 0;
  color: #fff;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  font-size: 18px;
  margin: 15px 0;
  width: 100%;
  background-position: center;
  @media (max-width: 750px) {
    background-size: contain !important;
    font-size: 15px;
  }
  i {
    background: rgba(0, 0, 0, 0) url(../../assets/images/victory-icon.png) no-repeat scroll center center / contain;
    display: inline-block;
    height: 70px;
    position: relative;
    top: -2px;
    width: 70px;
    z-index: 9;
  }
}

// New Css
.wrapper.campaign-wrapper {
  transform: scale(0.7);
  width: 1600px;
  .content {
    margin-top: -16%;
    width: 73%;
    .image-section {
      position: relative;
      .xDollar-outer {
        height: 120px;
        left: 41%;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        top: 54%;
        transform: translateX(-50%);
        width: 75%;
        .xDollar {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          span {
            background-color: rgba(78, 77, 120, 0.5);
            border: 1px solid #fff;
            color: #fff;
            display: inline-block;
            font-size: 17px;
            font-weight: 600;
            height: 70px;
            line-height: 22px;
            margin: 0 10px 5px 0;
            padding: 10px;
            text-align: center;
            width: 23%;
            &.name {
              background: none;
              border: none;
              margin: 0;
              overflow: hidden;
              padding: 0;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              height: auto;
            }
            &.dollor {
              background: none;
              border: none;
              margin: 0;
              overflow: hidden;
              padding: 0;
              width: auto;
              height: auto;
            }
          }
        }
      }
      .percentage {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 50px;
        top: 6%;
        span {
          color: #1f6064;
          display: inline-block;
          font-size: 24px;
          margin-bottom: 20px;
          text-align: right;
          &.full {
            font-size: 30px;
          }
          &.half {
            font-size: 30px;
          }
        }
      }
      .progress-bar {
        background-color: #569169;
        height: 583px;
        position: absolute;
        right: 20px;
        top: 7%;
        width: 5px;
      }
      .per-arrow {
        position: absolute;
        right: 25px;
        top: 22%;
      }
    }
    .dedication {
      h1 {
        background-color: #7fc342;
        color: #fff;
        font-size: 35px;
        margin: 0 auto;
        padding: 15px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: 60%;
        &::after {
          border-bottom: 13px solid transparent;
          border-left: 13px solid transparent;
          border-right: 13px solid transparent;
          border-top: 13px solid #7fc342;
          content: "";
          display: block;
          position: absolute;
          left: 50%;
          top: 100%;
          transform: translateX(-50%);
        }
      }
      .dedication-box-outer {
        display: flex;
        margin-top: 35px;
        width: 100%;
        .dedication-boxes {
          border: 1px solid #bababa;
          text-align: center;
          width: 20%;
          .boxes {
            .header {
              background-color: #7fc342;
              color: #fff;
              padding: 10px 0;
              h4 {
                font-size: 22px;
                margin-bottom: 2px;
                text-transform: uppercase;
              }
              h5 {
                font-size: 18px;
                margin-bottom: 0;
                text-transform: uppercase;
              }
            }
            .ded-content {
              padding: 20px 0;
              h5.multiple {
                font-size: 22px;
                margin-bottom: 10px;
              }
              h5.dedor {
                font-size: 22px;
                margin-bottom: 10px;
              }
              h5.dollor {
                font-size: 26px;
                font-weight: 600;
                margin-bottom: 0px;
              }
            }
          }
        }
      }
    }
  }
  aside {
    margin-top: -16%;
    width: 22%;
  }
}

.banner {
  position: relative;
  .btnPlay {
    color: #023a5b;
    cursor: pointer;
    font-size: 60px;
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 43%;
    transform: translate(-50%, -50%);
    z-index: 1;
    span {
      align-items: center;
      border: 8px solid #023a5b;
      border-radius: 50%;
      display: inline-flex;
      height: 100px;
      justify-content: center;
      width: 100px;
    }
  }
}

.banner-for-mobile {
  display: none;
}

// Carousel Css
.image-gallery-content .image-gallery-slide .image-gallery-image {
  width: 100%;
  // height: 180px;
  // object-fit: cover;
}

.fluid-width-video-wrapper {
  height: 400px;
  padding: 0 !important;
  iframe {
    height: 400px;
    width: 700px;
  }
}

.campaign-wrapper #description {
  display: none;
}

.mask {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 11;
}
.description-popup {
  display: block !important;
  height: 420px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 720px;
  z-index: 111;
  .btn-close {
    align-items: center;
    background: #fff;
    border-radius: 50%;
    color: #333;
    cursor: pointer;
    display: flex;
    font-size: 25px;
    height: 35px;
    justify-content: center;
    position: absolute;
    right: -20px;
    top: -20px;
    width: 35px;
  }
}

@media (max-width: 1600px) {
  .wrapper.campaign-wrapper {
    width: 1440px;
  }
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 14.5px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 525px;
  }
}

@media (max-width: 1440px) {
  .wrapper.campaign-wrapper {
    width: 1366px;
  }
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 11.5px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 499px;
  }
}

@media (max-width: 1366px) {
  .wrapper.campaign-wrapper {
    width: 1280px;
  }
  .wrapper.campaign-wrapper .content .image-section .xDollar-outer .xDollar span {
    width: 31%;
  }
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 8.5px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 469px;
  }
}

@media (max-width: 1280px) {
  .wrapper.campaign-wrapper {
    width: 1140px;
  }
  .wrapper.campaign-wrapper .content,
  .wrapper.campaign-wrapper aside {
    margin-top: -19%;
  }
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 3.8px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 422px;
  }
}

@media (max-width: 1140px) {
  .wrapper.campaign-wrapper {
    width: 940px;
  }
  .campaign-wrapper .content .image-section img.animationImage {
    height: 455px;
  }
  .wrapper.campaign-wrapper .content,
  .wrapper.campaign-wrapper aside {
    margin-top: -23%;
  }
  .wrapper.campaign-wrapper .content {
    width: 70%;
  }
  .wrapper.campaign-wrapper aside {
    width: 26%;
  }
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 3px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 411px;
  }
}

@media (max-width: 1024px) {
  .campaign-wrapper .content .image-section img.animationImage {
    height: 460px;
  }
  .wrapper.campaign-wrapper .content .image-section .percentage {
    right: 40px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    right: 12px;
  }
  .wrapper.campaign-wrapper .content .image-section .per-arrow {
    right: 17px;
  }
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 3.5px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 416px;
  }
}

@media (max-width: 995px) {
  .wrapper.campaign-wrapper {
    transform: scale(1);
    width: 100%;
    .content {
      margin-top: 0;
      width: 100%;
    }
    aside {
      margin-top: 0;
      width: 98%;
    }
  }
  .campaign-wrapper .content .image-section img.animationImage {
    height: auto;
  }
  .wrapper.campaign-wrapper .content .image-section .percentage {
    right: 50px;
    top: 6%;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    right: 20px;
    top: 7%;
  }
  .wrapper.campaign-wrapper .content .image-section .per-arrow {
    right: 25px;
  }
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 12px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 500px;
  }
  .description-popup {
    height: auto;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 111;
  }
  .fluid-width-video-wrapper {
    height: auto;
    padding-top: 56.25% !important;
  }
}

@media (max-width: 919px) {
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 8px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 464px;
  }
}

@media (max-width: 867px) {
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 5.5px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 439px;
  }
}

@media (max-width: 830px) {
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 3.6px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 421px;
  }
}

@media (max-width: 768px) {
  .wrapper.campaign-wrapper .content .image-section .percentage span {
    margin-bottom: 1px;
  }
  .wrapper.campaign-wrapper .content .image-section .progress-bar {
    height: 392px;
  }
  .banner .btnPlay {
    top: 40%;
  }
}

@media (max-width: 767px) {
  .MuiDialogContentText-root-81 .fluid-width-video-wrapper {
    iframe {
      height: auto;
      width: 100%;
    }
  }
  .campaign-wrapper .content .image-section {
    display: none;
  }
}

@media (max-width: 575px) {
  .banner {
    display: none;
  }
  // .banner .btnPlay {
  //   font-size: 26px;
  //   top: 38%;
  //   span {
  //     border: 5px solid #023a5b;
  //     height: 45px;
  //     width: 45px;
  //   }
  // }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    // height: 52px;
    object-fit: cover;
  }
  .banner-for-mobile {
    display: block;
  }
}

#flyPostUX {
  background-color: rgba(78, 77, 120, 0.5);
  border: 1px solid #fff;
  color: #fff;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  height: 44px;
  line-height: 22px;
  margin: 0 10px 5px 0;
  padding: 10px;
  min-width: 150px;
  text-align: center;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #741414;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.donorBox {
  background-color: #efefef;
  padding: 50px 0;
  .wrapper {
    width: 90%;
  }
}

.video-tag-latest {
  text-align: center;
  img {
    cursor: pointer;
    max-width: 35%;
  }
  .captions {
    margin-top: 30px;
    h4 {
      font-size: 18px;
      letter-spacing: 1px;
      color: #4c4b4b;
      font-weight: 300;
      margin: 0 0 5px;
    }
    h5 {
      font-size: 15px;
      letter-spacing: 1px;
      color: #4c4b4b;
      font-weight: 300;
      margin: 0 0 8px;
    }
    h6 {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 1px;
      line-height: 23px;
      color: #4c4b4b;
      margin: 0;
    }
  }
  .fluid-width-video-wrapper {
    height: 400px;
    iframe {
      height: 400px;
    }
  }
}

.image-slider-new .image-gallery-content .image-gallery-slide .image-gallery-image {
    border: 10px solid #FFF;
    object-fit: cover;
    max-width: 100%;
    height: 400px;
}

@media (max-width: 575px) {
  .image-slider-new .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: auto;
  }
}
