#additional-info-dialog {
  .title {
    background: #008eb5;
    h2 {
      color: #fff;
    }
  }
  .container {
    padding: 20px;
    @media (min-width: 768px) {
      width: 700px;
    }
    p {
      font-size: 11px;
      color: #cb3837;
    }
    .closable {
      position: relative;
      .close {
        display: block;
        position: absolute;
        color: #fff;
        background: #000;
        height: 25px;
        width: 25px;
        text-align: center;
        font-size: 20px;
        border-radius: 50%;
        right: 0;
        top: 0;
      }
    }
    .items {
      margin: 10px 0;
      .icon {
        button {
          width: 100px;
          margin-top: 10px;
          padding: 5px 0;
          font-size: 16px;
          background: #e26060;
          color: #fff;
          border: none;
        }
      }
      .img-container {
        width: 100px;
        height: 100px;
        border: 1px solid #ddd;
        display: flex;
        justify-content: center;
        img {
          height: 100%;
        }
      }
    }
    .item {
      display: flex;
      flex-direction: column;
      padding: 5px;
      label, input {
        font-size: 16px;
        font-weight: 400;
      }
      input {
        padding: 5px;
      }
      .input {
        border-radius: 4px;
        border: 1px solid #ddd;
      }
    }
    .add-btn {
      margin-right: 10px;
      padding: 5px 15px;
      border: none;
      border-radius: 4px;
      background: #d3e8e3;
      color: #3b7862;
    }
  }
  .actions {
    button {
      padding: 5px 15px;
      font-size: 16px;
      border-radius: 4px;
      border: none;
      &:first-child {
        background: #d9544f;
        color: #fff;
      }
      &:nth-child(2) {
        background: #008eb5;
        color: #fff;
      }
    }
  }
}