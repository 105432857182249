.main-row {
    align-items: center;
    display: flex;
    padding: 0 0 50px;
}
.heart-percentage {
    // align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10%;
    position: relative;

    .raisedAmountLatest {
        bottom: -145px;
        color: #4c4b4b;
        font-size: 18px;
        position: absolute;
        width: 100%;
        span {
            color: #2995c3;
            font-size: 30px;
            left: 0;
            position: relative;
            top: 0;
            transform: none;
            &.left-span {
                color: #4c4b4b;
                display: inline-block;
                font-size: 18px;
                width: 35%;
            }
        }
    }
}
.heart-percentage .percent {
    margin-bottom: 15px;
    margin-left: 15%;
    position: relative;
}
.heart-percentage .heart-percent {
    bottom: -3px;
    left: 14px;
    position: absolute;
}
.heart-percentage .heart-shadow {
    bottom: -30px;
    left: 13%;
    position: absolute;
}
.heart-percentage span {
    left: 23%;
    position: absolute;
    top: 62%;
    transform: translate(-50%, -75%);
}
.heart-percentage .heart-content {
    margin-top: 20px;
    text-align: center;
}
.heart-percentage .heart-content h6 {
    color: #4c4b4b;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.heart-percentage .heart-content h1 {
    color: #4c4b4b;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 1px;
}
.clock {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.clock .time {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}
.clock .time p {
    align-items: center;
    color: #222;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 5px;
}
.clock .time p .top-span {
    display: inline-block;
    font-size: 40px;
    font-weight: 600;
    line-height: 45px;
    margin-bottom: 5px;
}
.clock .time p .bottom-span {
    display: inline-block;
    font-size: 16px;
}
.clock .time .colon-box {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 7px;
}
.clock .time .colon {
    background-color: #c9c9c9;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-bottom: 12px;
    width: 10px;
}
.clock .text {
    color: #4c4b4b;
    font-size: 16px;
    letter-spacing: 1px;
    line-height: 23px;
    margin-top: 15px;
    padding: 0 15px;
    text-align: center;
}

.heart-goals {
    margin: 25px 0 0 10%;
    .goal-point {
        font-size: 18px;
        font-weight: 400;
        span {
            color: #4c4b4b;
            .amount {
                color: #2995c3;
            }
            &.left-span {
                display: inline-block;
                width: 35%;
            }
        }
    }
}
