@font-face {
    font-family: "ITC Avant Garde Std Md";
    src: url("https://db.onlinewebfonts.com/t/3831f86f2cadb3833c352221a0cf4cc0.ttf") format("truetype");
}
@font-face {
    font-family: "ITC Avant Garde Std Md Bold";
    src: url("https://db.onlinewebfonts.com/t/edded80c08cc143337556b92a5450213.ttf") format("truetype");
}
@font-face {
    font-family: "Bebas Neue";
    src: url("https://db.onlinewebfonts.com/t/ba65c7020043d1d406686743e82e2e5d.ttf") format("truetype");
}
@font-face {
    font-family: "Bebas Neue Thin";
    src: url("https://db.onlinewebfonts.com/t/c4ffb8b3299d39d29411e154254f8fc4.ttf") format("truetype");
}
@font-face {
    font-family: "ITC Avant Garde Std Demi";
    src: url("https://db.onlinewebfonts.com/t/6fd12f277cfc5de5a582980bdd94a77e.ttf") format("truetype");
}
@font-face {
    font-family: "Proxima Nova";
    src: url("https://db.onlinewebfonts.com/t/0e43789441fd9a44c0d0e0b25641f953.ttf") format("truetype");
}
@font-face {
    font-family: "Avant Guard Reg";
    src: url("https://db.onlinewebfonts.com/t/6b8369c629d25ccce7cd49df45aee6cb.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy";
    src: url("../../../assets/fonts/Gilroy-Black.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaABlack";
    src: url("../../../assets/fonts/MarkSimonsonProximaNovaAltBlack.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaAExtraBold";
    src: url("../../../assets/fonts/MarkSimonsonProximaNovaAltExtrabold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaABold";
    src: url("../../../assets/fonts/MarkSimonsonProximaNovaAltBold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaASemiBold";
    src: url("../../../assets/fonts/MarkSimonsonProximaNovaAltSemibold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaALight";
    src: url("../../../assets/fonts/MarkSimonsonProximaNovaAltLight.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaBold";
    src: url("../../../assets/fonts/ProximaNovaBold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ProximaNovaRegular";
    src: url("../../../assets/fonts/ProximaNovaRegular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ITCAvantGardeStdMd";
    src: url("../../../assets/fonts/ITCAvantGardeStdMd.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ITCAvantGardeStdBk";
    src: url("../../../assets/fonts/ITCAvantGardeStdBk.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "ITCAvantGardeStdXLt";
    src: url("../../../assets/fonts/ITCAvantGardeStdXLt.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
}

/***** Font-family ****/
$Roboto: "Roboto", sans-serif;
$Roboto-slab: "Roboto Slab", serif;
$Open-Sans: "Open Sans", sans-serif;
$Nunito: "Nunito", sans-serif;
$ITC-Avant: "ITC Avant Garde Std Md";
$ITC-Avant-Bold: "ITC Avant Garde Std Md Bold";
$ITC-Avant-Demi: "ITC Avant Garde Std Demi";
$bebas-neue: "Bebas Neue";
$bebas-neue-thin: "Bebas Neue Thin";
$proxima-nova: "Proxima Nova";
$avant-guard: "Avant Guard Reg";
$gilroy: "Gilroy";
$proxima-nova-a-black: "ProximaNovaABlack";
$proxima-nova-a-extra-bold: "ProximaNovaAExtraBold";
$proxima-nova-a-bold: "ProximaNovaABold";
$proxima-nova-a-semibold: "ProximaNovaASemiBold";
$proxima-nova-a-light: "ProximaNovaALight";
$proxima-nova-bold: "ProximaNovaBold";
$proxima-nova-regular: "ProximaNovaRegular";
$ITC-Avant-Garde-Medium: "ITCAvantGardeStdMd";
$ITC-Avant-Garde-BK: "ITCAvantGardeStdBk";
$ITC-Avant-Garde-Light: "ITCAvantGardeStdXLt";

.header {
    border-top: 3px solid #41b549;
    z-index: 10;
    padding: 5px 15px;
    position: relative;
    .navbar {
        margin-bottom: 0;
        padding: 0;
        background: #fff;
        border: none;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        .navbar-brand {
            padding: 0;
        }
        .navbar-nav {
            padding: 0;
            transition: all 0.25s;
            .nav-item {
                position: relative;
                display: block;
                padding-left: 10px;
                padding-right: 10px;
                .nav-link {
                    color: #222;
                    cursor: pointer;
                    font-size: 14px;
                    font-family: $proxima-nova-a-bold;
                    border-bottom: 2px solid transparent;
                    padding: 10px 0 2px;
                    transition: all 0.3s ease-in-out;
                    &:hover {
                        border-bottom: 2px solid #4bbdd9;
                        color: #4bbdd9;
                    }
                    &.dashboard-latest {
                        background-color: #cecece;
                        border: none !important;
                        color: #fff !important;
                        font-size: 14px !important;
                        font-weight: 600;
                        margin: 0;
                        padding: 8px;
                        text-align: center;
                        text-transform: uppercase;
                        width: 130px;
                        &:hover {
                            background-color: #4bbdd9 !important;
                            border: none !important;
                            color: #fff !important;
                        }
                    }
                    &.login-latest {
                        background-color: #898989;
                        border: none !important;
                        color: #fff !important;
                        font-size: 14px !important;
                        font-weight: 600;
                        padding: 8px 0;
                        text-align: center;
                        text-transform: uppercase;
                        width: 130px;
                        &:hover {
                            background-color: #4bbdd9 !important;
                            border: none !important;
                            color: #fff !important;
                        }
                    }
                }
            }
        }
    }
}

.heading-latest {
    background-color: #92d6e8;
    display: flex;
    justify-content: center;
    margin: auto;
    padding: 0 !important;
    position: relative;
    transform: skewX(-30deg);
    width: 670px;
}
.heading-latest h1 {
    background-color: #fff;
    font-family: $proxima-nova-bold;
    font-size: 48px;
    font-weight: 800;
    color: #4cbdda;
    margin: 0;
    padding: 11px 0;
    text-align: center;
    transform: skewX(0deg);
    width: 650px;
}
.heading-latest h1 span {
    display: inline-block;
    transform: skewX(30deg);
}
.heading-latest.fees-heading {
    background-color: #a4d998;
    margin-bottom: 60px;
    width: 270px;
}
.heading-latest.fees-heading h1 {
    color: #64bf4f;
    font-family: $proxima-nova-a-extra-bold;
    width: 250px;
}
// .header .container {
//     max-width: 1400px;
// }
// .header .navbar {
//     background-color: #fff !important;
// }
// .header .navbar-light .navbar-nav .nav-item {
//     position: relative;
// }
// .header .navbar-light .navbar-nav .active::after {
//     background-color: #4bbdd9;
//     content: "";
//     display: block;
//     height: 3px;
//     left: 50%;
//     position: absolute;
//     top: 100%;
//     transform: translateX(-50%);
//     width: 70px;
// }
// .header .navbar-light .navbar-nav .active.dashboard::after,
// .header .navbar-light .navbar-nav .active.login::after {
//     background: none;
// }
// .header .navbar-light .navbar-nav .nav-link.dashboard {
//     background-color: #cecece;
//     color: #fff;
//     font-size: 14px;
//     font-weight: 600;
//     margin: 0 10px;
//     text-align: center;
//     text-transform: uppercase;
//     width: 130px;
// }
// .header .navbar-light .navbar-nav .nav-link.login {
//     background-color: #898989;
//     color: #fff;
//     font-size: 14px;
//     font-weight: 600;
//     text-align: center;
//     text-transform: uppercase;
//     width: 130px;
// }
@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

.banner-latest {
    background-color: #fafbfd;
}
.banner-latest .container {
    max-width: 1400px;
    width: 1170px;
}
.banner-latest .banner-content {
    margin: 50px 0 0 20%;
    text-align: center;
}
.banner-latest .banner-content .logo-div {
    align-items: center;
    display: flex;
    margin-left: 6%;
}
.banner-latest .banner-content .logo-div img {
    height: 60px;
}
.banner-latest .banner-content .logo-div p {
    color: #222222;
    font-family: $proxima-nova-a-light;
    font-size: 20px;
    font-weight: 300;
    margin: 19px 0 0 -12px;
}
.banner-latest .banner-content p {
    color: #222;
    font-family: $proxima-nova-a-bold;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
}
.banner-latest .banner-content h1 {
    color: #378ac9;
    font-family: $proxima-nova-a-extra-bold;
    font-size: 60px;
    font-weight: 800;
    line-height: 50px;
    margin: 0;
    text-transform: uppercase;
}
.banner-latest .banner-content h3 {
    color: #222;
    font-family: $proxima-nova-a-black;
    font-size: 36px;
    font-weight: 800;
    margin: 0;
    text-transform: uppercase;
}
.banner-latest .banner-content h3 span {
    color: #378ac9;
    font-family: $proxima-nova-a-light;
    font-size: 38px;
    font-weight: 100;
}
.banner-latest .banner-content h5 {
    color: #222;
    font-size: 23px;
    margin: 0;
}
.banner-latest .banner-content h5 span.new {
    font-family: $ITC-Avant-Garde-Light;
    font-weight: 100;
    margin-right: 5px;
}
.banner-latest .banner-content h5 span.flat {
    font-family: $ITC-Avant-Garde-Medium;
    font-weight: 600;
    margin-right: 5px;
}
.banner-latest .banner-content h5 span.from {
    font-family: $ITC-Avant-Garde-BK;
    font-weight: 400;
}
.banner-latest .banner-content .btn-schedule {
    background: linear-gradient(90deg, #3280c3 0%, #46b4e1 50%, #6dcbd6 100%);
    border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(108, 99, 255, 0.3);
    color: #fff;
    font-family: $proxima-nova;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 1px;
    margin-top: 60px;
    padding: 0 15px;
    text-transform: uppercase;
}

.meet-pledje {
    background-color: #f0f9fd;
    padding: 50px 0;
}
.meet-pledje .container {
    max-width: 1400px;
}
.meet-pledje .img-box {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 350px; // added this line for dec. updated ui
    position: relative;
}
.meet-pledje .img-box h4 {
    color: #4797b1;
    font-family: $proxima-nova;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px;
    text-align: center;
}
.meet-pledje .img-box .shadow {
    height: 34px;
    width: 272px;
}
.meet-pledje .img-box .shadow-img {
    margin-top: -2px;
}
.meet-pledje .meet-content {
    margin: 27px auto 0;
    max-width: 514px;
    position: relative;
}
.meet-pledje .meet-content .quotes {
    left: 0;
    position: absolute;
    top: 0;
}
.meet-pledje .meet-content p {
    color: #333333;
    font-family: $proxima-nova;
    font-size: 22px;
    line-height: 46px;
    margin: 0;
    padding-left: 70px;
}
.meet-pledje .meet-content p.author-name {
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    padding: 0 75px 0 0;
    text-align: right;
}
.meet-pledje .meet-content p.author-name span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    margin: 5px 0 0;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

.why-pledje {
    background-color: #fafbfd;
    padding: 40px 0 60px;
}
.why-pledje .container {
    max-width: 1600px;
}
.why-pledje .img-box {
    margin-top: 22%;
    text-align: center;
}
.why-pledje .img-box img {
    width: 90%;
}
.why-pledje h1 {
    color: #000;
    font-family: $proxima-nova-a-bold;
    font-size: 60px;
    font-weight: 600;
    line-height: 60px;
    margin: 0 0 80px;
    padding: 0 0 15px;
    position: relative;
    text-align: center;
}
.why-pledje h1::after {
    background-color: #4bbdd9;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: 70px;
}
.why-pledje .icon-box-outer {
    display: flex;
    // height: 105px;
    justify-content: center;
    margin-bottom: 30px;
}
.why-pledje .icon-box-outer .icon-box {
    margin-top: 8px;
    width: 65px;
}
.why-pledje .icon-box-outer p {
    color: #000;
    font-family: $proxima-nova-a-semibold;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    margin: 0 0 0 20px;
    width: 225px;
}
.why-pledje .icon-box-outer p span {
    display: block;
}

.why-pledje .icon-box-outer p span.bold-span {
    font-family: $proxima-nova-a-extra-bold;
}

.why-pledje .icon-box-outer p span.bold-span.bold-span-inline-block {
    display: inline-block;
}

.features {
    background-color: #f0f9fd;
    padding: 0 0 50px;
}
.features .container {
    max-width: 1600px;
}
.features .img-box {
    margin-top: -88px;
    text-align: center;
}
.features .img-box img {
    width: 85%;
}
.features .feature-content {
    max-width: 600px;
    text-align: center;
}
.features h1 {
    color: #222;
    font-family: $proxima-nova-a-extra-bold;
    font-size: 44px;
    margin: 40px 0 20px;
    position: relative;
    // text-align: center;
}
.features p {
    color: #6e6e6e;
    font-family: $proxima-nova-a-semibold;
    font-size: 24px;
    line-height: 33px;
    margin: 0 0 75px;
    // text-align: center;
}
.features p span {
    display: block;
}
.features p span.fund {
    font-size: 26px;
}
.features .btn-box .btn-learn {
    background: linear-gradient(90deg, #3280c3 0%, #46b4e1 50%, #6dcbd6 100%);
    border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(108, 99, 255, 0.3);
    color: #fff;
    font-family: $proxima-nova;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    letter-spacing: 2px;
    padding: 0 30px;
    text-transform: uppercase;
}

.available-campaign {
    background-color: #4bbdd9;
    padding: 50px 0;
}
.available-campaign p {
    font-family: $proxima-nova-a-light;
    font-size: 19px;
    line-height: 30px;
    color: #fafbfd;
    margin: 30px auto;
    max-width: 825px;
    text-align: center;
}
.available-campaign p span {
    color: #000;
    font-weight: 600;
}
.available-campaign .row {
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.available-campaign .icon-box-outer {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20%;
}
.available-campaign .icon-box-outer .icon-box {
    align-items: center;
    background-color: #fafbfd;
    border: 2px solid rgba(223, 221, 221, 0.24);
    border-radius: 50%;
    box-shadow: 0px 12px 8px rgba(0, 0, 0, 0.07);
    display: flex;
    height: 140px;
    justify-content: center;
    width: 140px;
}
.available-campaign .icon-box-outer p {
    color: #222;
    font-family: $proxima-nova-a-bold;
    font-size: 22px;
    line-height: 30px;
    height: 60px;
    margin: 15px 0 50px;
    text-align: center;
}
.available-campaign .icon-box-outer p span {
    display: block;
}
.available-campaign .icon-box-outer .btn-learn {
    background-color: #f9fbfd;
    border-radius: 0;
    box-shadow: 0px 5px 10px rgba(108, 99, 255, 0.3);
    color: #4bbdd9;
    font-family: $proxima-nova-bold;
    font-size: 14px;
    height: 50px;
    text-transform: uppercase;
    width: 150px;
}

.campaign-features {
    background-color: #fafbfd;
    padding: 50px 0 0;
}
.campaign-features h1 {
    color: #000;
    font-family: $proxima-nova-a-bold;
    font-size: 48px;
    font-weight: 600;
    margin: 0 0 50px;
    padding: 0 0 15px;
    position: relative;
    text-align: center;
}
.campaign-features h1::after {
    background-color: #4bbdd9;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: 70px;
}
.campaign-features .icon-box-outer {
    display: flex;
    height: 105px;
    justify-content: center;
    margin-bottom: 40px;
}
.campaign-features .icon-box-outer .icon-box {
    margin-top: 8px;
    width: 65px;
}
.campaign-features .icon-box-outer p {
    color: #000;
    font-family: $proxima-nova-bold;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin: 0 0 0 20px;
    width: 225px;
}
.campaign-features .icon-box-outer p span {
    display: block;
}

.team {
    background-color: #4cbdda;
    padding: 50px 0;
}
.team h1 {
    color: #fff;
    font-family: $proxima-nova-a-extra-bold;
    font-size: 48px;
    font-weight: 600;
    margin: 0 0 50px;
    padding: 0 0 10px;
    position: relative;
    text-align: center;
}
.team h1::after {
    background-color: #fff;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: 70px;
}
.team .icon-box-outer {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
}
.team .icon-box-outer .icon-box {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 15px 15px rgba(14, 178, 200, 0.1);
    display: flex;
    height: 105px;
    justify-content: center;
    width: 105px;
}
.team .icon-box-outer p {
    color: #3a3a3a;
    font-family: $proxima-nova-a-semibold;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 0 20px;
    width: 280px;
}
.team .icon-box-outer p span {
    display: block;
}

.fees {
    background: #64bf4f url("../../../assets/images/latestImages/fees-icon/fees-bg-img.png") no-repeat
        scroll center center/cover;
    padding: 30px 0;
}
.fees .container {
    max-width: 800px;
}
.fees .icon-box-outer {
    align-items: center;
    display: flex;
    height: 106px;
    justify-content: center;
    margin-bottom: 50px;
}
.fees .icon-box-outer .icon-box {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 15px 15px rgba(14, 178, 200, 0.1);
    display: flex;
    height: 105px;
    justify-content: center;
    width: 105px;
}
.fees .icon-box-outer p {
    color: #000;
    font-family: $proxima-nova-a-semibold;
    font-size: 18px;
    line-height: 28px;
    margin: 0 0 0 30px;
    width: 170px;
}
.fees .icon-box-outer p span {
    display: block;
}
.fees .btn-box {
    display: flex;
    justify-content: center;
}
.fees .btn-box .btn-learn {
    background-color: #f9fbfd;
    border-radius: 0;
    box-shadow: 0px 5px 10px rgba(108, 99, 255, 0.3);
    color: #64bf4f;
    font-family: $proxima-nova-bold;
    font-size: 14px;
    height: 50px;
    text-transform: uppercase;
    width: 150px;
}

.case-studies {
    background-color: #fff;
    padding: 50px 0;
}
.case-studies h1 {
    color: #222222;
    font-family: $proxima-nova-bold;
    font-size: 48px;
    margin: 0 0 50px;
    padding: 0 0 15px;
    position: relative;
    text-align: center;
}
.case-studies h1::after {
    background-color: #4bbdd9;
    content: "";
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
    width: 70px;
}
.case-studies p.bonus-img {
    background-color: #78b743;
    height: 40px;
    margin: 0;
}
.case-studies p.goal {
    background-color: #6baa37;
    color: #fff;
    font-family: $proxima-nova-a-bold;
    font-size: 16px;
    height: 40px;
    margin: 0;
    padding: 10px 0;
    text-align: center;
}
.case-studies p.goal span {
    margin-right: 10px;
}
.case-studies p.raised {
    background-color: #78b743;
    color: #fff;
    font-family: $proxima-nova-a-bold;
    font-size: 16px;
    height: 40px;
    margin: 0;
    padding: 10px 0;
    text-align: center;
}
.case-studies p.raised span {
    margin-right: 10px;
}
.case-studies .content {
    background-color: #f3f3f3;
    height: 160px;
    padding: 15px;
    width: 100%;
}
.case-studies .content p {
    display: flex;
    font-family: $proxima-nova-regular;
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 5px;
}
.case-studies .content p span {
    color: #75b63f;
    margin-right: 10px;
}
.case-studies .logo-box {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}

.newsletter {
    background-color: #4cbdda;
    color: #fff;
    height: 170px;
    padding: 0;
    text-align: center;
}
.newsletter .img-box {
    margin-top: -50px;
}
.newsletter .content {
    padding: 50px 0;
}
.newsletter .content h5 {
    font-family: $proxima-nova-a-bold;
    font-size: 24px;
    margin-bottom: 5px;
}
.newsletter .content p {
    font-family: $proxima-nova-bold;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 30px;
}
.newsletter .content .input-box {
    display: flex;
    justify-content: center;
}
.newsletter .content .input-box input {
    border: none;
    color: #353535;
    flex: 3;
    font-family: $proxima-nova-a-semibold;
    font-size: 16px;
    height: 40px;
    padding: 0 15px;
}
.newsletter .content .input-box .btn-subscribe {
    background-color: #3f3d56;
    border: none;
    border-radius: 0;
    color: #f9f9f9;
    flex: 1;
    font-family: $proxima-nova-a-semibold;
    font-size: 16px;
    height: 40px;
}

.footer-home {
    padding: 30px 0;
}
.footer-home p {
    margin: 0;
    text-align: center;
    width: 100%;
}

@media (max-width: 1600px) {
    .banner-latest .container,
    .meet-pledje .container {
        max-width: 1400px;
        width: 100%;
    }
    .why-pledje .container,
    .features .container {
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 991px) {
    .banner-latest .banner-content .logo-div {
        margin-left: 30%;
    }
    .meet-pledje .meet-content {
        margin: 50px auto 0;
    }
    .why-pledje .img-box {
        margin: 0 0 50px;
    }
    .why-pledje .img-box img {
        width: 40%;
    }
    .features .img-box {
        margin-top: -155px;
    }
    .available-campaign .row {
        flex-direction: column;
    }
    .available-campaign .icon-box-outer {
        margin-bottom: 30px;
        width: 50%;
    }
    .available-campaign .icon-box-outer p {
        height: auto;
        margin: 15px 0 20px;
    }
}

@media (max-width: 576px) {
    .header .navbar .navbar-nav .nav-item .nav-link {
        text-align: center;
    }
    .header .navbar .navbar-nav .nav-item .nav-link.login-latest,
    .header .navbar .navbar-nav .nav-item .nav-link.dashboard-latest {
        width: 100%;
    }
    .heading-latest {
        width: 435px;
    }
    .heading-latest h1 {
        font-size: 32px;
        width: 415px;
    }
    .banner-latest .banner-content {
        margin: 20px 0 0 0;
    }
    .banner-latest .banner-content .logo-div {
        margin-left: 18%;
    }
    .banner-latest .banner-content .btn-schedule {
        margin: 20px 0;
    }
    .meet-pledje {
        padding: 30px 0;
    }
    .meet-pledje .meet-content {
        margin: 20px auto 0;
    }
    .meet-pledje .meet-content p {
        line-height: 30px;
    }
    .why-pledje .img-box {
        margin: 0 0 20px;
    }
    .why-pledje .img-box img {
        width: 50%;
    }
    .why-pledje h1 {
        margin: 0 0 40px;
    }
    .why-pledje .icon-box-outer {
        margin: 0 0 30px 5%;
    }
    .features {
        padding: 0 0 30px;
    }
    .features .img-box {
        margin-top: -68px;
    }
    .features .img-box img {
        width: 60%;
    }
    .features h1 {
        font-size: 36px;
        margin: 20px 0 5px;
    }
    .features p {
        font-size: 20px;
        line-height: 25px;
        margin: 0 0 20px;
    }
    .features p span.fund {
        font-size: 22px;
    }
    .available-campaign {
        padding: 30px 0;
    }
    .available-campaign p {
        max-width: 465px;
    }
    .available-campaign .icon-box-outer.event {
        margin-bottom: 0;
    }
    .campaign-features {
        padding: 20px 0;
    }
    .campaign-features h1 {
        font-size: 36px;
        margin: 0 0 30px;
    }
    .campaign-features .icon-box-outer {
        align-items: center;
        height: auto;
        margin: 0 0 30px 3%;
    }
    .campaign-features .icon-box-outer .icon-box {
        margin: 0;
    }
    .campaign-features .icon-box-outer p {
        width: 85%;
    }
    .campaign-features .icon-box-outer p span {
        display: inline;
    }
    .team {
        padding: 20px 0;
    }
    .team h1 {
        font-size: 36px;
        margin: 0 0 40px;
    }
    .team .img-box {
        margin-bottom: 40px;
    }
    .team .icon-box-outer {
        align-items: center;
        margin: 0 0 30px 5%;
    }
    .team .icon-box-outer:last-child {
        margin-bottom: 5px;
    }
    .heading-latest.fees-heading,
    .fees .icon-box-outer {
        margin-bottom: 30px;
    }
    .case-studies h1 {
        font-size: 36px;
        margin: 0 0 40px;
    }
    .case-studies .img-box img {
        width: auto;
    }
    .case-studies .content {
        height: auto;
    }
    .case-studies .logo-box {
        margin: 0;
    }
}

@media (max-width: 415px) {
    .heading-latest {
        width: 350px;
    }
    .heading-latest h1 {
        font-size: 26px;
        width: 330px;
    }
    .banner-latest .banner-content .logo-div {
        margin-left: 3%;
    }
    .meet-pledje .meet-content {
        margin: 10px auto 0;
    }
    .meet-pledje .meet-content p {
        font-size: 16px;
    }
    .why-pledje {
        padding: 10px 0 60px;
    }
    .why-pledje .icon-box-outer {
        align-items: center;
        margin: 0 0 23px 3%;
    }
    .why-pledje .icon-box-outer .icon-box {
        margin: 0;
    }
    .why-pledje .icon-box-outer p {
        line-height: 22px;
        width: 85%;
    }
    .why-pledje .icon-box-outer p span {
        display: inline;
    }
    .why-pledje .icon-box-outer p span.bold-span.bold-span-inline-block {
        display: inline;
        margin: 0;
    }
    .features h1 {
        font-size: 26px;
    }
    .features p {
        font-size: 15px;
    }
    .features p span.fund {
        font-size: 17px;
    }
    .why-pledje .img-box {
        margin: 0 0 25px;
    }
    .why-pledje h1 {
        font-size: 36px;
        margin: 0 0 30px;
        line-height: 30px;
    }
    .available-campaign p {
        font-size: 15px;
        line-height: 24px;
        margin: 20px auto;
    }
    .available-campaign .icon-box-outer p {
        font-size: 18px;
        line-height: 26px;
        margin: 15px 0;
    }
    .campaign-features h1 {
        font-size: 30px;
    }
    .campaign-features .icon-box-outer p {
        line-height: 24px;
    }
    .team .img-box {
        margin-bottom: 20px;
    }
    .team .icon-box-outer {
        justify-content: flex-start;
        margin: 0 0 25px 3%;
    }
    .team .icon-box-outer .icon-box {
        height: 95px;
        width: 95px;
    }
    .team .icon-box-outer p {
        line-height: 24px;
        width: auto;
    }
    .fees .icon-box-outer .icon-box {
        height: 95px;
        width: 95px;
    }
    .fees .icon-box-outer {
        height: auto;
        justify-content: flex-start;
        margin: 0 0 20px 3%;
    }
    .fees .icon-box-outer p {
        line-height: 24px;
        margin: 0 0 0 20px;
        width: 69%;
    }
    .fees .icon-box-outer p span {
        display: inline;
    }
    .newsletter .content {
        padding: 20px 0 30px;
    }
    .newsletter .content p {
        margin-bottom: 15px;
    }
}

@media (max-width: 400px) {
    .heading-latest {
        width: 335px;
    }
    .heading-latest h1 {
        font-size: 24px;
        width: 315px;
    }
    .banner-latest .banner-content p {
        font-size: 13px;
    }
    .banner-latest .banner-content h1 {
        font-size: 56px;
    }
    .banner-latest .banner-content h3 {
        font-size: 33px;
    }
    .banner-latest .banner-content h3 span {
        font-size: 35px;
    }
    .banner-latest .banner-content h5 {
        font-size: 21.5px;
    }
}

.comparison-dialog {
    ::ng-deep .p-dialog {
        .p-dialog-header {
            padding: 0;
            .p-dialog-header-icons {
                background-color: #fff;
                border-radius: 50%;
                justify-content: center;
                position: absolute;
                right: -15px;
                top: -15px;
            }
        }
        .p-dialog-content {
            margin-top: -2px;
            padding: 0;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-track {
                background: lighten(#78b743, 10%);
            }
            &::-webkit-scrollbar-thumb {
                background: #fff;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 22px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgba(95, 94, 94, 0.808);
                cursor: pointer;
            }
        }
    }
}

.newsletter {
    ul {
        margin-top: 50px;
        li {
            font-size: 20px;
            padding: 5px 0;
            a {
                color: #fff;
                padding: 0;
            }
        }
    }
}
.newsletter .img-box img {
    max-width: 82%;
}

//for thumnail logic
.meet-pledje {
    .img-box {
        img.thumbnail-img {
            height: 215px;
            max-width: 100%;
            position: absolute;
            cursor: pointer;
            top: 46px;
            transition: all 0.5s ease-in-out;
            &:hover {
                transform: scale(1.03);
            }
        }
    }
}

.video-box-latest-1 {
    margin-left: auto;
    margin-right: 20px;
}

.video-box-latest-2 {
    margin-left: 20px;
}

@media (max-width: 576px) {
    .video-box-latest-1,
    .video-box-latest-2 {
        margin: 0;
    }
    ::ng-deep .p-dialog {
        width: 90vw !important;
    }
    .newsletter {
        height: auto;
        ul {
            margin-top: 4px;
        }
    }
}

/*===== back to top button css ==*/

.scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 15px;
    transition: all 0.2s ease-in-out;
    width: 50px;
    height: 50px;
    background: #000;
    z-index: 9999;
    border-radius: 50%;
    line-height: 54px;
    text-align: center;
    @media (max-width: 767px) {
        bottom: -40px;
        right: 10px;
    }
    i {
        color: #fff;
        font-size: 20px;
    }
}
