.product_pledged {
  color: #2995c3;
  float: left;
  font-size: 55px;
  font-weight: bolder;
  margin: 0px 0 10px;
  text-align: center;
  width: 100%;
  line-height: 60px;
}

.RSPBprogressBar {
  overflow: hidden;
  position: relative;
  i {
    position: absolute;
    width: 20px;
    height: 20px;
    color: #fff;
    border-radius: 50%;
    background: #2995c3;
    right: 0;
    line-height: 20px;
    text-align: center;
  }
}

.percent_br {
  display: block;
  text-align: center;
  float: left;
  width: 100%;
  margin: 0 0 20px;
  color: #909090;
  font-size: 16px;
  position: relative;
}
