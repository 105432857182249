.user-container {
  .user-main-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
    padding: 0 60px;
    .export-btn {
      background: #333;
      border-radius: 4px;
      padding: 6px 16px;
      margin-right: 10px;
      height: 40px;
      a {
        color: #fff;
        font-size: 18px;
        i {
          padding-right: 5px;
        }
      }
    }
    .add-btn {
      height: 40px;
      background: #aae7f8;
      color: #008eb5;
      border-radius: 4px;
      box-shadow: none;
      font-size: 18px;
      line-height: normal;
      i {
        padding-right: 5px;
      }
    }
  }
}
