@import '../../index.scss';

.prospect-form {
  font-family: $ITC-Avant;
  padding: 10px 0 80px 0;
  max-height: 75vh;
  overflow: auto;
  height: 100vh;
  .prev-next-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .dialog-arrow-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 5px;
      padding: 10px;
      background: #e9585b;
      color: white;
      width: 130px;
      font-size: 15px;
      font-weight: bold;
    }
  }
  .prospect-label {
    color: #292929;
    font-size: 14px;
    font-weight: 300;
    padding: 0 5px;
    font-family: $ITC-Avant;
  }
  // 
  .donor-form {
    padding: 5px;
    .input {
      font-weight: 400;
      input {
        font-size: 14px;
        border-radius: 4px;
        background: #fff;
        padding: 6px 8px;
      }
      p {
        font-size: 12px;
      }
      :before {
        border: none;
      }
      :after {
        border: none;
      }
      &:hover {
        ::before {
          border: none !important;
        }
      }
    }
    .header {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #53c1e8;
      .heading {
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .contents{
      padding-top: 10px;
      background:#ebf8fd;
      .suggested-donation{
        width: 50%;
        background:#3c92b5;
        color:white;
        font-weight: bold;
        padding: 8px 15px 15px;
        margin: 0 5px;
        .prospect-label {
          color: #fff;
        }
        .input {
          width: 100%;
          color: #5191B1;
          font-weight: 700;
          font-size: 20px;
          input {
            text-align: center;
          }
        }
      }
      .donor-form-content {
        width: 50%;
        padding: 0 5px;
        .call-btn {
          width: 100%; 
          height: 35px;
          color: white;
          font-weight: 400;
          font-size: 16px;
          text-align: center;
          border: none;
          text-align-last: center;
          border-radius: 4px;
        }
        .call-btn:focus {
          outline: none;
        }
      }
      .donation-form {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        .anonymous-section {
          .field {
            width: 100%;
          }
        }
        .field{
          width: 50%;
          padding: 0 5px;
          margin-bottom: 10px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .prospect-label {
            padding: 0;
            margin: 5px 0;
          }
          .input {
            width: 100%;
            border: none;
          }
          .donation-select {
            background: #fff;
            border: 1px solid #fff;
            border-radius: 4px;
            font-size:14px;
            margin-right: 5px;
            padding: 8px 5px;
            width: 100%;
            color: #53C0E8;
            text-align: center;
            div {
              padding: 0;
            }
            div:focus {
              outline: none;
              background: none;
            }
          }
          .donation-select:focus {
            outline: none;
          }
        }
      }
      .complete-form {
        position: relative;
        padding-bottom: 10px;
        margin: 10px 0;
        .show-more {
          // background: #757575;
          background: #53c1e8;
          color: #fff;
          text-align: center;
          height: 30px;
          width: 120px;
          line-height: 35px;
          font-size: 14px;
          font-weight: 400;
          border-radius: 5px;
          &:hover {
            cursor: pointer;
            // background: #5e5e5e;
            background: #3c92b5;
          }
        }
      }
    }
  }
  //
  .manual-donation-form{
    padding: 5px;
    .header {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #3c92b5;
      .heading{
        color: #fff;
        font-size: 18px;
        font-weight: 700;
      }
    }
    .manual-form-container {
      padding: 10px 0;
      .contents {
        .items {
          margin: 15px 0;
          .item {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .info {
              width: 50%;
              padding-right: 10px;
              p {
                font-size: 12px;
              }
              .head {
                display: flex;
                justify-content: space-between;
                label {
                  padding: 0;
                  font-size: 18px;
                }
              }
            }
            .input-items {
              width: 50%;
              .input {
                width: 100%;
              }
            }
          }
        }
        .input {
          border-radius: 4px;
          border: 1px solid #e7e7e7;
          font-size: 14px;
        }
        .row1 {
          margin-bottom: 5px;
          .amount-lbl {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .donation-input {
              margin-left: 5px;
              position: relative;
              color: #53C0E8;
              .currency {
                position: absolute;
                top: 8px;
                left: 10px;
                z-index: 1;
              }
              .input {
                padding-left: 45px;
                font-weight: 400;
              }
            }
          }
          .radio-grid {
            .radio-btn {
              border: none;
              box-shadow: none;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 5px;
              margin: 0;
              span {
                font-family: $ITC-Avant;
                font-size: 14px;
              }
            }
          }
        }
        .row2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;
          .donation-select {
            border: 1px solid #e7e7e7;
            border-radius: 4px;
            font-size:14px;
            margin-right: 5px;
            padding: 8px 5px;
            width: 50%;
            color: #53C0E8;
            text-align: center;
            div {
              padding: 0;
            }
            div:focus {
              outline: none;
              background: none;
            }
          }
          .donation-select:focus {
            outline: none;
          }
        }
        .row3 {
          margin-bottom: 5px;
        }
        .row4 {
          margin-bottom: 10px;
          padding: 0 5px;
          .prospect-label {
            padding: 0;
          }
          .input {
            width: 100%;
            input {
              padding: 7px 6px;
            }
          }
          .routing {
            padding-right: 5px;
          }
          .account {
            padding-left: 5px;
          }
        }
        .row5 {
          margin-bottom: 10px;
          padding: 0 5px;
          .prospect-label {
            padding: 0;
          }
          .input {
            width: 100%;
            padding: 8px 5px;
          }
        }
      }
    }
  }
  .update-btn {
    text-align: center;
    padding: 15px 0;
    display: flex;
    justify-content: center;
  }
  #bottom-button-bar {
    z-index: 99;
    position: fixed;
    width: 960px;
    bottom: 0;
    background: #fff;
    display: flex;
    justify-content: space-evenly;
    button {
      width: 20%;
      color: #fff;
      font-size: 14px;
      padding: 10px;
    }
    .red-btn {
      background-color: #d9544f;
    }
    .blue-btn {
      background-color: #53c1e8;
    }
    .green-btn {
      background: #61c0a3;
    }
  }
}

.status-options {
  text-align: center;
  &:focus {
    outline: none;
  }
  &:hover{
    cursor: pointer;
  }
}
