.newsletter-latest {
    background-color: #4cbdda;
    color: #fff;
    height: 215px;
    padding: 0;
    text-align: center;
}
.newsletter-latest .img-box {
    margin-top: -30px;
}
.newsletter-latest .content {
    padding: 50px 0;
}
.newsletter-latest .content h5 {
    margin-bottom: 5px;
}
.newsletter-latest .content p {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 30px;
}
.newsletter-latest .content .input-box {
    display: flex;
    justify-content: center;
}
.newsletter-latest .content .input-box input {
    border: none;
    flex: 3;
    height: 40px;
    padding: 0 15px;
}
.newsletter-latest .content .input-box .btn-subscribe {
    background-color: #3f3d56;
    border: none;
    border-radius: 0;
    color: #f9f9f9;
    flex: 1;
    height: 40px;
}

.footer-home {
    background-color: #fff;
    bottom: 0;
    box-shadow: none;
    padding: 30px 0;
    position: sticky;
}
.footer-latest p {
    margin: 0;
    text-align: center;
    width: 100%;
}

@media (max-width: 576px) {
    .newsletter {
        height: auto;
    }
    .newsletter-latest .content {
        padding: 20px 0;
    }
    .newsletter .content .input-box {
        margin: 0;
    }
    .footer-home {
        padding: 20px 0;
    }
}
