@import "../../index.scss";

.donation_calc_part {
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 10;
  background: #fff;
  #donation_wrapper {
    box-shadow: 0 -1px 9px #000;
    @media (max-width: 991px) {
      display: none;
    }
  }
}

.donation_container {
  .plan_donation {
    background: #96a434;
    height: 65px;
    width: 63%; //here 65%
    padding-left: 70px;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .or {
      position: absolute;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      background: #96a434;
      right: -13px;
      height: 25px;
      width: 25px;
      border-radius: 50%;
    }

    .options {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      margin-right: 10px;
    }

    .donation_option {
      width: 100%;
      padding: 0 10px;
      text-align: center;
      border-right: 1px solid #4a4a4a;
      .option {
        margin: 0;
        input[type="radio"] {
          display: inline-block;
          -webkit-appearance: none;
          width: 22px;
          height: 22px;
          border: 2px solid #4a4a4a;
          border-radius: 50%;
          outline: none;
          margin: 0;
          background: #fff;
          top: 2px;
        }
        input[type="radio"]:hover {
          box-shadow: 0 0 5px 0px #4a4a4a inset;
        }
        input[type="radio"]:before {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          margin: 6px;
          border-radius: 50px;
        }
        input[type="radio"]:checked:before {
          background: #4a4a4a;
        }
        .price {
          font-size: 14px;
          color: #4a4a4a;
          font-weight: 600;
          white-space: normal;
          padding-left: 5px;

          input {
            width: 50%;
            border: none;
            background: transparent;
            border-bottom: 1px solid #4a4a4a;
          }
          input::placeholder {
            color: #4a4a4a;
          }
        }
      }
      .duration {
        .duration_text {
          color: #fff;
          margin: 0;
        }
      }
    }
    .donation_option:last-child {
      border: none;
    }
  }

  .onetime_donation {
    text-transform: uppercase;
    float: left;
    width: 37%; //35%
    padding-right: 50px; //70
    height: 65px;
    background: #4a4a4a;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .label {
      color: #fff;
      width: 30%;
      text-align: center;
      margin: 0;
      font-size: 16px;
      padding: 0;
      float: right;
      // new block for latest ui
      &.label-latest {
        align-items: center;
        color: #2d2d2d;
        display: flex;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 18px;
        text-transform: capitalize;
        img {
          max-width: 100%;
          width: 55%;
          margin-top: 11px;
        }
      }
    }

    .form_text {
      width: 250px;
      position: relative;
      input {
        width: 100%;
        height: 55px;
        border: 1px solid #4a4a4a;
        padding: 5px 50% 5px 55px;
        color: #4a4a4a;
        font-size: 50px;
        font-family: $bebas-neue;
        text-align: right;
      }
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input::-webkit-input-placeholder {
        text-align: right;
        // transform: scale(2);
        // transform-origin: 40% 60%;
      }
      .input_label {
        position: absolute;
        left: 10px;
        top: 12px;
        color: #4a4a4a;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        padding-right: 10px;
      }
      .decimal {
        position: absolute;
        right: 35%;
        top: 15px;
        font-weight: 700;
        font-size: 25px;
        color: #4a4a4a;
      }
      // new added for latest ui
      &.form_text_latest {
        margin-left: 30px;
        input {
          font-size: 35px;
          padding: 10px 45% 5px 55px;
        }
        .input_label {
          top: 20px;
        }
        .decimal {
          font-family: "Bebas Neue";
          font-size: 35px;
          font-weight: 500;
          right: 31%;
          top: 5px;
        }
      }
    }
  }
}

.result_container {
  padding: 0 70px;
}

.donate_result {
  width: 100%;
  float: right;
  padding: 10px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .currency-selector {
    display: flex;
    align-items: center;
    border: 3px solid #91a607;
    .text {
      padding: 5px;
      font-size: 19px;
      font-weight: 600;
      background: #404040;
      color: #fff;
    }
    .select {
      font-size: 16px;
      text-transform: uppercase;
      padding: 0 10px;
      font-weight: 600;
      color: #91a607;
      div {
        padding: 0;
      }
      svg {
        display: none;
      }
    }
  }
  .donate-button {
    width: 250px;
    #donate_now {
      font-family: $ITC-Avant-Bold;
      background: #91a607;
      font-size: 25px;
      font-weight: 700;
      width: 100%;
      border: none;
      color: white;
      height: 65px;
      line-height: 68px;
    }
  }

  &.donate_result_latest {
    padding: 3px 0;
    .match_price {
      label {
        color: #666666;
      }
      .match_info {
        font-size: 0;
        strong {
          background: #d0d0d0;
          border: 2px solid #d0d0d0;
          box-shadow: none;
          color: #5b5a5a !important;
          .cross {
            font-family: "Bebas Neue";
            font-size: 14px;
            left: 14px;
            top: 2px;
          }
          .multi_factr {
            color: #5b5a5a !important;
            font-family: "Bebas Neue";
            font-size: 24px;
            left: 22px;
            top: 0;
          }
        }
      }
    }
    .org_receiving {
      padding: 0;
      label {
        color: #666666;
      }
      p {
        border-bottom: 2px solid #333;
        padding: 5px 15px 50px 0px;
        .cur-icon {
          color: #4d4c4c;
          font-size: 43px;
        }
        .total {
          color: #4d4c4c;
          font-family: "Bebas Neue Thin";
          font-size: 45px;
          font-weight: 800;
        }
      }
    }
    .donate-button {
      margin-left: 15%;
      #donate_now {
        font-family: "Bebas Neue";
        font-size: 40px;
        font-weight: 500;
        height: 55px;
        letter-spacing: 3px;
        line-height: 60px;
      }
    }
  }
}

.match_price {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .match_info {
    display: inline-block;
    strong {
      -webkit-box-shadow: 0px 5px 5px 0px rgba(74, 74, 74, 0.8);
      -moz-box-shadow: 0px 5px 5px 0px rgba(74, 74, 74, 0.8);
      box-shadow: 0px 5px 5px 0px rgba(74, 74, 74, 0.8);
      margin: 0 5px 0 -5px;
      background: #91a607;
      display: inline-block !important;
      font-size: 15px;
      color: #fff !important;
      height: 50px;
      width: 50px;
      line-height: 50px;
      border: 2px solid #91a607;
      border-radius: 50%;
      text-align: center;
      position: relative;
      .multi_factr {
        top: 2px;
        color: #fff;
        font-size: 32px;
        position: absolute;
        left: 18px;
        font-family: $ITC-Avant;
      }
      .cross {
        top: 1px;
        left: 8px;
        position: absolute;
        font-size: 13px;
        font-family: $ITC-Avant;
      }
    }
  }
  p {
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 25px;
    padding: 5px;
  }
  label {
    color: #404040;
    line-height: 16px;
    width: 150px;
    text-align: right;
    margin: 0;
    font-family: $ITC-Avant;
    font-weight: 300;
  }
}

.org_receiving {
  display: flex;
  align-items: center;
  padding-right: 50px;
  p {
    background: #fff;
    display: inline-block !important;
    font-size: 22px;
    color: #404040 !important;
    height: 50px;
    line-height: 50px;
    text-align: center;
    position: relative;
    .equal {
      position: absolute;
      left: -5px;
      font-family: $ITC-Avant;
      font-weight: 300;
      font-style: italic;
    }
    .cur-icon {
      font-family: $bebas-neue-thin;
      margin-left: 15px;
      margin-right: 5px;
      color: #91a607;
      font-size: 50px;
      font-weight: 700;
    }
    .total {
      color: #91a607;
      font-size: 60px;
      font-family: $bebas-neue;
      font-weight: 300;
    }
  }
  p {
    margin-left: 10px;
    margin-bottom: 0;
    font-size: 25px;
  }
  label {
    color: #404040;
    line-height: 16px;
    width: 180px;
    text-align: right;
    margin: 0;
    font-family: $ITC-Avant;
    font-weight: 300;
  }
}

.mobile_donate {
  background: #91a607;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
  p {
    display: block;
    font-size: 25px;
    font-weight: normal;
    color: white;
    margin: 15px;
  }
}

@media (max-width: 1180px) {
  .donation_container {
    .onetime_donation {
      .form_text {
        input {
          font-size: 40px;
        }
        .decimal {
          right: 33%;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .donation_container {
    .plan_donation {
      padding-left: 0;
    }
    .onetime_donation {
      padding-right: 0;
    }
  }
}

#menu-currency {
  ul {
    li {
      font-size: 14px;
      text-transform: uppercase;
    }
  }
}
