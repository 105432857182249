.empty {
  width: 100%;
  overflow-x: auto;
  height: 15px;
  .scroll {
    height: 10px;
  }
}

.get-full-report-latest {
  position: absolute;
  right: 30%;
  top: 23%;
  button {
    background: #333;
    color: white;
    padding: 10px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    border-radius: 5px;
    width: 170px;
    &:hover {
      background: #333;
    }
  }
}

@media (max-width: 1421px) {
  .get-full-report-latest {
    position: absolute;
    right: 34%;
    top: 25.5%;
  }
}

#divcontents {
  table.table-print-latest {
    margin-bottom: 20px;
    width: 100%;
    th, td {
      border: 1px solid #bdbdbd;
      color: red;
      text-align: left;
    }
  }
}