@import '../../index.scss';

#donation-summary {
  .fixed {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 999;
  }
  .items-total {
    max-height: 110px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .common-style {
    background: #5585b5;
    color: #fff;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    font-family: $avant-guard;
    .items-total {
      .item {
        margin: 3px 0;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        .title {
          text-transform: uppercase;
          font-size: 14px;
          width: 60%;
        }
        span {
          font-family: $ITC-Avant-Bold;
          color: #4a4a4a;
          padding: 5px;
          background: #ddd;
          min-width: 35px;
          height: 30px;
          line-height: 25px;
          text-align: center;
        }
        .calc {
          width: 40%;
          justify-content: space-between;
        }
      }

      .empty-cart {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        i {
          font-size: 32px;
        }
      }
    }
    .total-donation {
      text-align: center;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      background: #75a1c4;
      span {
        font-size: 32px;
      }
    }
    .matching {
      justify-content: center;
      align-items: center;
      height: 75px;
      label {
        font-size: 15px;
      }
      .factor {
        align-items: center;
        justify-content: center;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background: #fff;
        color: #4a4a4a;
        div {
          font-size: 32px;
        }
      }
    }
    .eq-sign {
      font-size: 32px;
      width: 25px;
      height: 75px;
      display: flex;
      align-items: flex-end;
    }
    .grand-total {
      font-size: 12px;
      //font-size: 16px;
      height: 75px;
      align-items: center;
      text-align: center;
      span {
        font-size: 26px;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .checkout-btn {
    align-self: center;
    button {
      font-size: 18px;
      background: #4a4a4a;
      border: none;
      padding: 0 15px;
    }
  }
  .donation-summary {
    .suggestion {
      width: 100%;
      margin-top: 5px;
      background: #6794bd;
      font-size: 18px;
      text-align: center;
      line-height: 40px;
    }
    .checkout-info {
      width: 70%;
      margin: 0 auto;
      align-items: center;
      justify-content: space-evenly;
      height: 125px;
      .items-total {
        width: 30%;
      }
      .right-sec {
        width: 70%;
        justify-content: space-around;
        align-items: center;
        .total-donation {
          width: 15%;
          height: 120px;
        }
        .monthly {
          align-items: center;
          justify-content: space-between;
          padding-bottom: 5px;
          width: 15%;
          input {
            background: #fff;
            padding: 5px;
            border: none;
            border-radius: 6px;
            color: #aaa;
            text-align: center;
            width: 65%;
          }
        }
        .matching {
          width: 15%;
        }
        .checkout-btn {
          align-self: center;
          button {
            height: 75px;
            font-size: 18px;
            background: #4a4a4a;
            border: none;
          }
        }
        .checkout-btn {
          button {
            height: 75px;
          }
        }
      }
      .switch-btn {
        margin-top: 5px;
        button {
          background: #fff;
          color: #058cb4;
          border: 2px solid #058cb4;
          border-radius: 4px;
          &:hover {
            background: #058cb4;
            color: #fff;
          }
        }
      }
    }
  }
  
  .mobile-donation-summary {
    .items-total {
      padding: 10px;
      .item {
        width: 100%;
        .calc {
          width: 40%;
        }
      }
      .text {
        text-align: center;
      }
    }
    .total-donation {
      height: 70px;
      background: none;
    }
    .total {
      width: 100%;
      justify-content: space-around;
      padding: 10px 0;
      .grand-total {
        width: 40%;
        text-align: center;
      }
    }
    .checkout-fixed {
      background: #5585b5;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      button {
        background: #91a607;
        width: 100%;
        height: 70px;
      }
    }
  }
}

@media (max-width: 767px) {
  #donation-summary{
    .common-style{
      .grand-total{
        max-height: 115px;
      }
    }
  }  
}
