@import '../../index.scss';

#helpdesk {
  position: fixed;
  z-index: 1;
  bottom: 0;
  right:5%;
  background: white;
  border:1px solid #17539e;
  margin-right: 1%;
  margin-bottom: 1%;
  padding:0px 0px;
  border-radius: 5px;
  .phone{
    margin-left:60%
  }
  .sub-header{
    background-color: #17539e;
    color: #17539e;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 2px;
    .email-us{
      background-color: #17539e;
      color: #fbf9f9;
      border:none;
      .heading-title{
        font-size:18px;
      }
    }
    .btn-close{
      margin-right:10px;
      background-color:  #17539e;
      color: #fbf9f9;
      border: none;
    }
    .btn-phone{
      margin-right:10px;
      background-color:  #17539e;
      color: #fbf9f9;
      border: none;
    }
  }
  .body{
    .email-form{
      .body-content{
        padding-left: 5px;
        padding-top:10px;
        padding-left:20px;
        .subtitle{
          color:grey;
        }
        .text-field{
          width: 95%;
          line-height: 5vh;
          border-radius: 8px;
          padding:2px 10px;
        }
        .btn-send{
          border-radius: 5px;
          font-size: 18px;
          margin-bottom: 5px;
          background-color: #17539e;
          color: #fbf9f9;
        }
      }
    }
    .email-response{
      .body-content{
        padding-top:10px;
        padding-left:20px;
        .subtitle{
          color:grey;
        }
        .label-field{
          width: 95%;
          border-radius: 8px;
          padding:2px 0px;
          color:grey
        }
    }
  }
}
} 