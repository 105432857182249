#project-sidebar {
  .goal {
    text-align: center;
    color: #909090;
    font-size: 26px;
    margin-top: 50px;
    span {
      text-transform: uppercase;
    }
  }
  .team-img-mobile {
    display: none;
  }
}

.team-goals {
  .time-remaining {
    text-align: center;
    position: relative;
    svg {
      height: 190px;
      position: absolute;
      left: 0;
      top: 80px;
    }
    // .CircularProgressbar-trail {
    //   stroke: rgb(35, 208, 76);
    // }
    .t-labels {
      text-align: center;
      line-height: 45px;
      top: 61%;
      right: 29%;
      transform: translate(-50%, -50%);
      position: absolute;
      div {
        color: #2586af;
      }
      .hr {
        font-size: 20px;
      }
      .min {
        font-size: 15px;
      }
      .sec {
        font-size: 10px;
      }
    }
    .t-timer {
      line-height: 45px;
      top: 60%;
      left: 44%;
      transform: translate(-50%, -50%);
      position: absolute;
      div {
        color: #2586af;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .hr {
        font-size: 32px;
        font-weight: 500;
      }
      .min {
        font-size: 26px;
        font-weight: 500;
      }
      .sec {
        font-size: 20px;
        font-weight: 300;
      }
    }
  }
}

.campaign-goal {
  color: #909090;
  text-align: center;
  p {
    font-size: 16px;
    font-weight: 500;
    span {
      color: #2586af;
    }
  }
}

.donor-list {
  max-width: 100%;
  margin-top: 20px;
  .tabs {
    width: 100%;
    button {
      min-width: 33.3%;
      z-index: 9;
      font-size: 12px;
      span {
        padding: 0;
      }
    }
    .MuiTab-selected-38 {
      height: 50px;
      background: #fff;
      color: rgba(0, 0, 0, 0.54);
      border-left: 1px solid #e7e7e7;
      border-top: 2px solid #e7e7e7;
      border-right: 1px solid #e7e7e7;
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  #description {
    .project-header {
      padding: 10px 25px;
    }
  }
}
@media (max-width: 1366px) {
  .team-goals .time-remaining .t-labels {
  right: 25%;
  }
  }
  
  @media (max-width: 1140px) {
  .team-goals .time-remaining .t-labels {
  right: 22%;
  }
  }




@media (max-width: 991px) {
  #project-sidebar {
    .time-remaining {
      max-width: 200px;
      margin: 0 auto;
      svg {
        height: 155px;
        top: 65px;
      }
      .t-labels {
        right: 22%;
        top: 60%;
        .hr {
          font-size: 18px;
        }
        .min {
          font-size: 13px;
        }
        .sec {
          font-size: 8px;
        }
      }
      .t-timer {
        left: 40%;
        top: 60%;
        .hr {
          font-size: 28px;
        }
        .min {
          font-size: 24px;
        }
        .sec {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #project-sidebar {
    .team-img-mobile {
      display: block;
    }
    .donor-list {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 612px) {
  #description {
    .project-header {
      padding: 10px 30px;
    }
  }
}

@media (max-width: 480px) {
  #description {
    .project-header {
      padding: 10px 25px;
    }
  }
}