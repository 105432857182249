#organization-dialog {
  .title {
    background: #008eb5;
    h2 {
      color: #fff;
    }
  }
  .container {
    padding: 20px;
    @media (min-width: 768px) {
      width: 700px;
    }
    .item {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 10px;
      label, input, textarea {
        font-size: 16px;
        font-weight: 400;
      }
      input, textarea {
        padding: 5px;
        border-radius: 4px;
        border: 1px solid #ddd;
        &:focus {
          outline: none;
        }
      }
      p {
        font-size: 11px;
        color: #cb3837;
      }
    }
  }
  .actions {
    button {
      padding: 5px 15px;
      font-size: 16px;
      border-radius: 4px;
      border: none;
      &:first-child {
        background: #d9544f;
        color: #fff;
      }
      &:nth-child(2) {
        background: #008eb5;
        color: #fff;
      }
    }
  }
}