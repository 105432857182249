@font-face {
  font-family: "ITC Avant Garde Std Md";
  src: url("https://db.onlinewebfonts.com/t/3831f86f2cadb3833c352221a0cf4cc0.ttf") format("truetype");
}
@font-face {
  font-family: "ITC Avant Garde Std Md Bold";
  src: url("https://db.onlinewebfonts.com/t/edded80c08cc143337556b92a5450213.ttf") format("truetype");
}
@font-face {
  font-family: "Bebas Neue";
  src: url("https://db.onlinewebfonts.com/t/ba65c7020043d1d406686743e82e2e5d.ttf") format("truetype");
}
@font-face {
  font-family: "Bebas Neue Thin";
  src: url("https://db.onlinewebfonts.com/t/c4ffb8b3299d39d29411e154254f8fc4.ttf") format("truetype");
}
@font-face {
  font-family: "ITC Avant Garde Std Demi";
  src: url("https://db.onlinewebfonts.com/t/6fd12f277cfc5de5a582980bdd94a77e.ttf") format("truetype");
}
@font-face {
  font-family: "Proxima Nova";
  src: url("https://db.onlinewebfonts.com/t/0e43789441fd9a44c0d0e0b25641f953.ttf") format("truetype");
}
@font-face {
  font-family: "Avant Guard Reg";
  src: url("https://db.onlinewebfonts.com/t/6b8369c629d25ccce7cd49df45aee6cb.ttf") format("truetype");
}

// body {
//   margin: 0;
//   padding: 0;
//   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//     "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

/***** Font-family ****/
$Roboto: 'Roboto', sans-serif;
$Roboto-slab: 'Roboto Slab', serif;
$Open-Sans: 'Open Sans', sans-serif;
$Nunito: 'Nunito', sans-serif;
$ITC-Avant: 'ITC Avant Garde Std Md';
$ITC-Avant-Bold: 'ITC Avant Garde Std Md Bold';
$ITC-Avant-Demi: 'ITC Avant Garde Std Demi';
$bebas-neue: 'Bebas Neue';
$bebas-neue-thin: 'Bebas Neue Thin';
$proxima-nova: 'Proxima Nova';
$avant-guard: 'Avant Guard Reg';

/***** Responsive Screens *****/
$iproLand: 1366px;
$iproPort: 1024px;
$iLand: 1024px;
$iPort: 768px;
$iphone_landscape: 768px;
$iphone_portrait: 414px;

/***** color ******/
$white: #fff;
$black: #000;
$dark_grey: #151515;
$grey: #404040;
$light_grey:#b4b5b7;
$light_green: #78b743; 
$green: #91a607;
$dark_green: #41b549;
$light_red: #f45757;
$dark_red: #dd2c28;
$light_blue: #4cbdda;
$dark_blue: #418ebc;

body {
  font-family: $Roboto;
  height: 100%;
    width: 100%;
}
html{
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}

ol, ul {
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  padding: 0;
}

// label { font-size: 16px; }
p { font-size: 13px; margin: 0 }
th { font-size: 14px !important;}
img { max-width: 100%; }
h1, h2, h3, h4, h5, h6 { margin-top: 0; }
label { margin: 0 }
.left { float: left; }
.right { float: right; }
.text-center { text-align: center; }
.text-right { text-align: right; }
.text-left { text-align: left; }
.width-100 { width: 100px; }
.width-75 { width: 75px; }
.width-50 { width: 50px; }
.width-25 { width: 25px; }
.p0 { padding: 0; }
.p10 { padding: 10px; }
.p15 { padding: 15px; }
.p20 { padding: 20px; }
.p30 { padding: 30px; }
.m0 { margin: 0; }
.m10 { margin: 10px; }
.m15 { margin: 15px; }
.m20 { margin: 20px; }
.m30 { margin: 30px; }
.mBottom { margin-bottom: 30px; }
.flex-row { display: flex; flex-direction: row }
.flex-column { display: flex; flex-direction: column }

button:focus { outline: none; }
input:focus { outline: none; }
.pointer:hover { cursor: pointer !important; }

.prospectmanagement {
  &:hover {
    background: rgb(0, 142, 181) !important;
  }
}

.campaignreports {
  &:hover {
    background: #7c6a9c !important;
  }
}

.precampaignvault {
  &:hover {
    background: silver !important;
  }
}

.captureDonation{
  &:hover {
    background: #008eb5 !important;
  }
}

.teammanagement{
  &:hover {
    background: #7CFC00 !important;
  }
}

.teamleadership {
  &:hover {
    background: #fcbe11 !important;
  }
}

.usermanagement {
  &:hover {
    background: #006400 !important;
  }
}

.newcampaign, .settings {
  &:hover {
    background: #008eb5 !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.main-wrap{
  margin: 0 15px;
}
.nowrap{
  white-space: nowrap;
}
.team-table-cell{
  padding: 5px 10px!important;
}