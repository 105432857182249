.shr_trsur_box {
  padding: 15px 0 0;
  border: 1px solid #e3c73f;
  background: #b1ddec none repeat scroll 0 0;
  clear: both;
  text-align: center;
  width: 100%;
  float: left;
  > p {
    font-size: 29px;
    text-transform: uppercase;
    margin: 0;
    color: #0085ad;
  }
  > h2 {
    font-size: 29px;
    text-transform: uppercase;
    color: #545455;
    margin: 5px 0 20px;
    line-height: 35px;
    span {
      font-size: 32px;
      font-weight: 200;
      color: #000000;
    }
  }
  .max-share-reached {
    width: 100%;
    float: left;
    display: block;
    padding: 0 0 20px;
    .reached_hsr {
      width: 60%;
      float: left;
      h1 {
        font-size: 66px;
        text-align: center;
        color: #e3c740;
        display: block;
        font-weight: bolder;
        line-height: 80px;
        margin: 0 0 7px;
      }
      p {
        display: block;
        font-size: 26px;
        font-weight: bolder;
        color: #4d4d4d;
        text-align: center;
        position: relative;
        text-transform: uppercase;
        margin-top: 0px;
      }
    }
    span {
      width: 30%;
      float: right;
      display: block;
      padding: 10px 10px 10px 0;
      img {
        @media (max-width: 750px) {
          width: 90%;
          margin-left: 10px;
        }
      }
    }
  }
}

.share-inprogress {
  padding: 25px 10px;
  background: #b1ddec none repeat scroll 0 0;
  clear: both;
  position: relative;
  .title {
    font-size: 18px;
  }
  h1 {
    color: rgb(41, 149, 195);
    text-align: center;
    padding: 20px 0;
    font-size: 72px;
    font-weight: 700;
    margin: 0;
  }
  h1:before {
    content: "";
    position: absolute;
    background: rgba(0, 0, 0, 0) url(../../assets/images/small-start-1.png) no-repeat scroll left top / 130px auto;
    display: block;
    height: 80px;
    right: 20px;
    top: 35px;
    width: 120px;
  }
  .shares-needed {
    font-size: 16px;
    b {
      .count {
        font-size: 24px;
      }
    }
  }
  .bottom-label {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
  }
}

#social-widget {
  width: 100%;
  position: relative;
  z-index: 1;
  float: left;
  background: #0085ad;
  margin: 0;
  text-align: center;
  margin-bottom: 40px;
  .socials-wrapper {
    ul {
      padding: 20px;
      margin: 0px;
      list-style: none;
      text-align: center;
      li {
        display: inline-block;
        vertical-align: middle;
        a {
          width: 36px;
          height: 36px;
          padding: 20px;
          border-radius: 999px;
        }
        a.s-facebook {
          padding-bottom: 0px;
          background: rgba(0, 0, 0, 0) url(../../assets/images/facebook.png) no-repeat scroll center top / 40px auto !important;
        }
        a.s-twitter {
          background: rgba(0, 0, 0, 0) url(../../assets/images/twitter.png) no-repeat scroll center top / 40px auto !important;
        }
        a.s-photo {
          background: rgba(0, 0, 0, 0) url(../../assets/images/photo.png) no-repeat scroll center top / 40px auto !important;
        }
        a.s-whatsapp {
          background: rgba(0, 0, 0, 0) url(../../assets/images/whats-app.png) no-repeat scroll center top / 40px auto !important;
        }
        a.s-video {
          background: rgba(0, 0, 0, 0) url(../../assets/images/video.png) no-repeat scroll center top / 40px auto !important;
        }
      }
    }
  }
  .total_share {
    min-height: 65px;
    background: #e3c73f;
    color: #005a75;
    font-size: 18px;
    text-transform: uppercase;
    padding: 11px 0 10px;
    position: relative;
    #result_share_get {
      z-index: 1;
      font-size: 36px;
      font-weight: bolder;
      position: relative;
      top: 2px;
      display: inline-block;
      line-height: 36px;
    }
    #result_share_get:before {
      z-index: -1;
      background: rgba(0, 0, 0, 0) url(../../assets/images/small-start-1.png) no-repeat scroll left top / 130px auto;
      content: " ";
      display: block;
      height: 80px;
      left: -66px;
      position: absolute;
      top: -45px;
      width: 120px;
    }
    :after {
      background: url(../../assets/images/box-bottom-shadow.png) no-repeat scroll center bottom;
      content: " ";
      display: block;
      height: 100px;
      position: absolute;
      right: 0;
      bottom: -34px;
      width: 100%;
    }
  }
}
