@import '../../index.scss';

.prospect-filters-tab {
  font-family: $proxima-nova;
  width: 100%;
  background: #aae7f8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  .directtion-col {
    display: flex;
    flex-direction: column;
  }
  .direction-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    label {
      margin: 0 5px;
    }
  }
  .filter-select {
    background: #fff;
    border-radius: 4px;
    font-size: 12px;
    #select-method {
      padding-left: 5px;
    }
    #select-status {
      padding-left: 5px;
    }
  }
  .filter-input {
    input {
      border-radius: 4px;
      background: #fff;
      font-size: 12px;
      padding: 5px;
    }
  }
  .col-1 {
    width: 10%;
  }
  .col-2 {
    width: 15%;
  }
  .col-3 {
    span {
      &:first-child {
        color: #008eb5;
      }
      &:nth-child(2) {
        font-size: 14px;
      }
    }
  }
  .col-4 {
    .filter-btn {
      background: #008eb5;
      font-size: 12px;
      margin-right: 5px;
      color: #fff;
    }
  }
}

#status-item {
  font-size: 14px;
}