.team_wrapper {
  background-image: linear-gradient(rgb(219, 247, 254), rgb(206, 254, 218));
  background-repeat: no-repeat;
  margin: auto;
  font-family: "roboto";

  .content {
    .team-img {
      display: block;
      margin-top: 30px;
      width: 100%;
    }
    @media (max-width: 991px) {
      .team-img {
        display: none;
      }
    }
  }

  .team-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 20%;
    }
    h1 {
      color: #0085ad;
      font-size: 42px;
      font-weight: 600;
      text-align: right;
    }
  }

  .team-center {
    justify-content: center !important;
    flex-direction: column;
    .info {
      text-align: center;
    }
    h1 {
      text-align: center;
    }
    .descr {
      text-align: center;
    }
  }

  .member {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .info {
      min-width: 20%;
      img {
        height: 110px;
        border-radius: 5px;
      }
      h4 {
        font-size: 30px;
        margin-top: 20px;
        color: #0085ad;
        text-align: center;
      }
    }

    #description {
      width: 100%;
      .desc {
        padding: 0 20px;
        margin-top: 10px;
        p {
          color: #747474;
          text-align: center;
        }
      }
      .show-more {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          margin: 0;
          color: #0085ad;
        }
        img {
          margin: 0 5px;
        }
        p:hover {
          cursor: pointer;
          color: #15718d;
        }
      }
    }
  }

  .donor {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    .icon {
      min-width: 20%;
      img {
        display: block;
        margin: 0 auto;
      }
      p {
        margin-top: 20px;
        font-weight: 600;
        color: #0085ad;
        text-align: center;
      }
    }
    #description {
      width: 100%;
    }
  }
}

@media (max-width: 991px) {
  .content {
    .team-title {
      display: block;
      text-align: center;
      h1 {
        font-size: 30px;
        text-align: center;
        margin: 10px 0;
      }
      img {
        width: 30%;
      }
    }
    .member {
      display: block;
      text-align: center;
      margin-top: 10px;
      .info {
        width: 100%;
        h4 {
          font-weight: 700;
        }
      }
    }
    .donor {
      display: block;
      text-align: center;
      .icon {
        width: 100%;
      }
    }
  }
}

@media (max-width: 450px) {
  .content {
    .team-title {
      h1 {
        font-size: 22px;
      }
      img {
        width: 40%;
      }
    }
    .member {
      .info {
        h4 {
          font-weight: 700;
          font-size: 22px;
        }
      }
    }
  }
}

//new animation css

.banner {
  position: relative;
  .btnPlay {
    color: #023a5b;
    cursor: pointer;
    font-size: 60px;
    left: 50%;
    opacity: 1;
    position: absolute;
    top: 43%;
    transform: translate(-50%, -50%);
    z-index: 1;
    span {
      align-items: center;
      border: 8px solid #023a5b;
      border-radius: 50%;
      display: inline-flex;
      height: 100px;
      justify-content: center;
      width: 100px;
    }
  }
}
.banner-for-mobile{
  display: none;
}

// Carousel Css
.image-gallery-content .image-gallery-slide .image-gallery-image {
  width: 100%;
  // height: 180px;
  // object-fit: cover;
}
.wrapper.team-wrapper {
  .content {
    padding: 0;
    .image-section {
      position: relative;
      .xDollar-outer {
        height: 104px;
        left: 41%;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;
        top: 54%;
        transform: translateX(-50%);
        width: 75%;
        .xDollar {
          align-items: center;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          span {
            background-color: rgba(78, 77, 120, 0.5);
            border: 1px solid #fff;
            color: #fff;
            display: inline-block;
            font-size: 17px;
            font-weight: 600;
            height: 60px;
            line-height: 22px;
            margin: 0 10px 5px 0;
            padding: 5px;
            text-align: center;
            width: 23%;
            &.name {
              background: none;
              border: none;
              margin: 0;
              overflow: hidden;
              padding: 0;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              height: auto;
            }
            &.dollor {
              background: none;
              border: none;
              margin: 0;
              overflow: hidden;
              padding: 0;
              width: auto;
              height: auto;
            }
          }
        }
      }
      .percentage {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 50px;
        top: 6%;
        span {
          color: #1f6064;
          display: inline-block;
          font-size: 20px;
          margin-bottom: 4.7px;
          text-align: right;
          &.full {
            font-size: 24px;
          }
          &.half {
            font-size: 24px;
          }
        }
      }
      .progress-bar {
        background-color: #569169;
        height: 365px;
        position: absolute;
        right: 20px;
        top: 7%;
        width: 5px;
      }
      .per-arrow {
        position: absolute;
        right: 25px;
        top: 22%;
      }
    }
  }
}

// @media (max-width: 1600px) {
//   .wrapper.team-wrapper .content .image-section .percentage span {
//     margin-bottom: 14.5px;
//   }
//   .wrapper.team-wrapper .content .image-section .progress-bar {
//     height: 525px;
//   }
// }

// @media (max-width: 1440px) {
//   .wrapper.team-wrapper .content .image-section .percentage span {
//     margin-bottom: 11.5px;
//   }
//   .wrapper.team-wrapper .content .image-section .progress-bar {
//     height: 499px;
//   }
// }

@media (max-width: 1366px) {
  .wrapper.team-wrapper .content .image-section .xDollar-outer .xDollar span {
    width: 31%;
  }
  .wrapper.team-wrapper .content .image-section .percentage span {
    // font-size: 20px;
    margin-bottom: 4.7px;
    // &.full {
    //   font-size: 24px;
    // }
    // &.half {
    //   font-size: 24px;
    // }
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    height: 364px;
  }
}

@media (max-width: 1280px) {
  .wrapper.team-wrapper .content .image-section .percentage span {
    margin-bottom: 4.5px;
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    height: 365px;
  }
}

@media (max-width: 1199px) {
  .team-wrapper .content .image-section img.animationImage {
    height: 455px;
  }
  .wrapper.team-wrapper .content .image-section .percentage span {
    margin-bottom: 8px;
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    height: 395px;
  }
}

@media (max-width: 1024px) {
  .team-wrapper .content .image-section img.animationImage {
    height: 460px;
  }
  .wrapper.team-wrapper .content .image-section .percentage {
    right: 40px;
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    right: 12px;
  }
  .wrapper.team-wrapper .content .image-section .per-arrow {
    right: 17px;
  }
  .wrapper.team-wrapper .content .image-section .percentage span {
    margin-bottom: 8px;
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    height: 400px;
  }
}

@media (max-width: 995px) {
  .wrapper.team-wrapper {
    .content {
      margin-top: 0;
      width: 100%;
    }
    aside {
      margin-top: 0;
      width: 98%;
    }
  }
  .wrapper.team-wrapper .content .image-section img.animationImage {
    height: auto;
  }
  .wrapper.team-wrapper .content .image-section .percentage {
    right: 50px;
    top: 6%;
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    right: 20px;
    top: 7%;
  }
  .wrapper.team-wrapper .content .image-section .per-arrow {
    right: 25px;
  }
  .wrapper.team-wrapper .content .image-section .percentage span {
    margin-bottom: 16px;
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    height: 478px;
  }
  .description-popup {
    height: auto;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    z-index: 111;
  }
  .fluid-width-video-wrapper {
    height: auto;
    padding-top: 56.25% !important;
  }
}

@media (max-width: 919px) {
  .wrapper.team-wrapper .content .image-section .percentage span {
    margin-bottom: 12.6px;
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    height: 444px;
  }
}

@media (max-width: 867px) {
  .wrapper.team-wrapper .content .image-section .percentage span {
    margin-bottom: 10.6px;
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    height: 421px;
  }
}

@media (max-width: 830px) {
  .wrapper.team-wrapper .content .image-section .percentage span {
    margin-bottom: 8.6px;
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    height: 404px;
  }
}

@media (max-width: 768px) {
  .wrapper.team-wrapper .content .image-section .percentage span {
    margin-bottom: 6px;
  }
  .wrapper.team-wrapper .content .image-section .progress-bar {
    height: 378px;
  }
  .banner .btnPlay {
    top: 40%;
  }
}

@media (max-width: 767px) {
  .MuiDialogContentText-root-81 .fluid-width-video-wrapper {
    iframe {
      height: auto;
      width: 100%;
    }
  }
  .team-wrapper .content .image-section {
    display: none;
  }
}

@media (max-width: 575px) {
  .banner{
    display: none;
  }
  .wrapper {
    padding: 0;
  }
  .content {
    padding: 0;
  }
  // .banner .btnPlay {
  //   font-size: 26px;
  //   top: 38%;
  //   span {
  //     border: 5px solid #023a5b;
  //     height: 45px;
  //     width: 45px;
  //   }
  // }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    // height: 52px;
    object-fit: cover;
  }
  .banner-for-mobile{
    display: block;
  }
}
